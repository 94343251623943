import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NbTokenLocalStorage } from '@nebular/auth';
import { environment } from '../environments/environment';

import * as moment from 'moment';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { BehaviorSubject, Subject } from 'rxjs';
import { FileuploadComponent } from './fileupload/fileupload.component';
import { ConfirmComponent } from './confirm/confirm.component';


@Injectable({
  providedIn: 'root',
})
export class ApiServiceService {
  baseUrl: string;
  baseUrlNode: string;

  headers;
  private internetStatusSubject = new BehaviorSubject<boolean>(navigator.onLine);
  internetStatus$ = this.internetStatusSubject.asObservable();
  isOnlineAccess;
  constructor(
    private http: HttpClient,
    private authService: NbTokenLocalStorage,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService
  ) {
    this.baseUrl = environment.baseUrl;
    this.baseUrlNode = environment.baseUrlNode;
    window.addEventListener('online', () => this.updateInternetStatus());
    window.addEventListener('offline', () => this.updateInternetStatus());
  }

  logobase64 = environment.logobase64;

  timezones = [
    "Pacific/Midway",
    "US/Samoa",
    "US/Hawaii",
    "US/Alaska",
    "US/Pacific",
    "America/Tijuana",
    "US/Arizona",
    "US/Mountain",
    "America/Chihuahua",
    "America/Mazatlan",
    "America/Mexico_City",
    "America/Monterrey",
    "Canada/Saskatchewan",
    "US/Central",
    "US/Eastern",
    "US/East-Indiana",
    "America/Bogota",
    "America/Lima",
    "America/Caracas",
    "Canada/Atlantic",
    "America/La_Paz",
    "America/Santiago",
    "Canada/Newfoundland",
    "America/Buenos_Aires",
    "Greenland",
    "Atlantic/Stanley",
    "Atlantic/Azores",
    "Atlantic/Cape_Verde",
    "Africa/Casablanca",
    "Europe/Dublin",
    "Europe/Lisbon",
    "Europe/London",
    "Africa/Monrovia",
    "Europe/Amsterdam",
    "Europe/Belgrade",
    "Europe/Berlin",
    "Europe/Bratislava",
    "Europe/Brussels",
    "Europe/Budapest",
    "Europe/Copenhagen",
    "Europe/Ljubljana",
    "Europe/Madrid",
    "Europe/Paris",
    "Europe/Prague",
    "Europe/Rome",
    "Europe/Sarajevo",
    "Europe/Skopje",
    "Europe/Stockholm",
    "Europe/Vienna",
    "Europe/Warsaw",
    "Europe/Zagreb",
    "Europe/Athens",
    "Europe/Bucharest",
    "Africa/Cairo",
    "Africa/Harare",
    "Europe/Helsinki",
    "Europe/Istanbul",
    "Asia/Jerusalem",
    "Europe/Kiev",
    "Europe/Minsk",
    "Europe/Riga",
    "Europe/Sofia",
    "Europe/Tallinn",
    "Europe/Vilnius",
    "Asia/Baghdad",
    "Asia/Kuwait",
    "Africa/Nairobi",
    "Asia/Riyadh",
    "Europe/Moscow",
    "Asia/Tehran",
    "Asia/Baku",
    "Europe/Volgograd",
    "Asia/Muscat",
    "Asia/Tbilisi",
    "Asia/Yerevan",
    "Asia/Kabul",
    "Asia/Karachi",
    "Asia/Tashkent",
    "Asia/Kolkata",
    "Asia/Kathmandu",
    "Asia/Yekaterinburg",
    "Asia/Almaty",
    "Asia/Dhaka",
    "Asia/Novosibirsk",
    "Asia/Bangkok",
    "Asia/Jakarta",
    "Asia/Krasnoyarsk",
    "Asia/Chongqing",
    "Asia/Hong_Kong",
    "Asia/Kuala_Lumpur",
    "Australia/Perth",
    "Asia/Singapore",
    "Asia/Taipei",
    "Asia/Ulaanbaatar",
    "Asia/Urumqi",
    "Asia/Irkutsk",
    "Asia/Seoul",
    "Asia/Tokyo",
    "Australia/Adelaide",
    "Australia/Darwin",
    "Asia/Yakutsk",
    "Australia/Brisbane",
    "Australia/Canberra",
    "Pacific/Guam",
    "Australia/Hobart",
    "Australia/Melbourne",
    "Pacific/Port_Moresby",
    "Australia/Sydney",
    "Asia/Vladivostok",
    "Asia/Magadan",
    "Pacific/Auckland",
    "Pacific/Fiji"
  ];



  /////////////////// Dashboard


  loginUser: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('accessUser')) !== null ? JSON.parse(localStorage.getItem('accessUser')) : null);
  sipoUser: BehaviorSubject<any> = new BehaviorSubject(JSON.parse(localStorage.getItem('sipo_user')) !== null ? JSON.parse(localStorage.getItem('sipo_user')) : null);




  updateLoggedUser() {
    this.loginUser.next(JSON.parse(localStorage.getItem('accessUser')) !== null ? JSON.parse(localStorage.getItem('accessUser')) : null);

  }


  updateSipoUser() {
    this.sipoUser.next(JSON.parse(localStorage.getItem('sipo_user')) !== null ? JSON.parse(localStorage.getItem('sipo_user')) : null);
  }
  updateSipoUserKey(key) {
    if (key) {
      //console.log('Next Value :',key);
      this.sipoUser.next(key);
    }
    else {
    }
  }

  updateInternetStatus() {
    this.internetStatusSubject.next(navigator.onLine);
  }

  checkStaffLogin() {
    return this.http.get(this.baseUrl + 'checkStaffLogin', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  isStaffLogin() {
    if (localStorage.getItem('is_staff_login') !== null && localStorage.getItem('is_staff_login') == 'true') {
      return true;
    }
    else {
      return false;
    }
  }

  toastrSuccess(title: any, message: any) {
    this.toastrService.success(title, message);
  }

  toastrError(title: any, message: any) {
    this.toastrService.danger(title, message);
  }

  toastrWarning(title: any, message: any) {
    this.toastrService.warning(title, message);
  }

  openFileUpload(data: any) {
    return this.dialogService.open(FileuploadComponent, {
      closeOnBackdropClick: false,
      closeOnEsc: false,
      context: {
        data: data,
      },
    });
  }

  confirmbox(data: any) {
    return this.dialogService.open(ConfirmComponent, {
      closeOnBackdropClick: false,
      closeOnEsc: false,
      context: {
        type: data.type,
        alertmsg: data.alertmsg,
        btntrue: data.btntrue,
        btnfalse: data.btnfalse,
      },
    });
  }


  getdashboardTotal(from_date, to_date) {
    return this.http.get(this.baseUrl + 'dashboard?from_date=' + from_date + '&to_date=' + to_date, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  getExternalDeliveryReports(from_date, to_date) {
    return this.http.get(this.baseUrl + 'get_external_deliveries?from=' + from_date + '&to=' + to_date, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  testUberDirectCred(id, secret) {
    let payload={
      'client_id':id,
      'client_secret':secret
    }
    return this.http.post(this.baseUrl + 'test_uber_direct',payload, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  refreshdashboardTotal(from_date, to_date) {
    return this.http.get(this.baseUrl + 'refresh_dashboard?from_date=' + from_date + '&to_date=' + to_date, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  getsalesBy(from_date, to_date) {
    return this.http.get(this.baseUrl + 'dashboard_salesby?from_date=' + from_date + '&to_date=' + to_date, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getticketsBy(from_date, to_date) {
    return this.http.get(this.baseUrl + 'dashboard_ticketstatus?from_date=' + from_date + '&to_date=' + to_date, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  getSaleTotal() {
    return this.http.get(this.baseUrl + 'chart', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  /////////////////// User

  getUserData() {
    return this.http.get(this.baseUrl + 'user', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  getUserInfo() {
    return this.http.get(this.baseUrl + 'get_user_info', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  //usermetadata


  getUserMetaData() {
    return this.http.get(this.baseUrl + 'userMetadata_get', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  getServiceHourConfig() {
    return this.http.get(this.baseUrl + 'get_service_hours_config', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  updateServiceHourConfig(data:any) {
    return this.http.post(this.baseUrl + 'service_hours_status_update',data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  getUserMetaDataUpdate(add) {

    const formdata = new FormData();
    formdata.append('email', add.email);
    formdata.append('name', add.name);
    formdata.append('description', add.description);
    formdata.append('mobile', add.mobile);
    formdata.append('country', add.country);
    formdata.append('delivery_buffer_time', add.delivery_buffer_time);
    formdata.append('pickup_buffer_time', add.pickup_buffer_time);
    formdata.append('pre_min_day', add.pre_min_day);
    formdata.append('city', add.city);
    formdata.append('online_inactive_reason', add.online_inactive_reason);
    formdata.append('website', add.website);
    formdata.append('postal_code', add.postcode);
    formdata.append('street_name', add.streetname);
    formdata.append('pickup', add.pickup ? '1' : '0');
    formdata.append('is_preorder', add.is_preorder ? '1' : '0');
    formdata.append('online_order_active', add.online_order_active ? '1' : '0');
    formdata.append('delivery', add.delivery ? '1' : '0');
    formdata.append('table_reservation', add.tablereserve ? '1' : '0');
    formdata.append('order_ahead', add.orderahead ? '1' : '0');
    formdata.append('order_for_later', add.orderlater ? '1' : '0');
    formdata.append('tax', add.tax);
    formdata.append('online_payment_enable', add.online_payment_enable ? '1' : '0');
    formdata.append('surcharge_enable', add.surcharge_enable ? '1' : '0');
    formdata.append('surcharge_percent', add.surcharge_percent);
    formdata.append('surcharge_amount', add.surcharge_amount);
    formdata.append('gst_no', add.gst_no);
    formdata.append('tax_exclusive', add.tax_exclusive ? '1' : '0');
    formdata.append('hide_tax_label', add.hide_tax_label ? '1' : '0');
    formdata.append('timezone', add.timezone);
    formdata.append('online_theme_color', add.online_theme_color);
    formdata.append('alert_info', add.alert_info);
    formdata.append('google_analytics_code', add.google_analytics_code);
    formdata.append('facebook_pixels_code', add.facebook_pixels_code);

    return this.http.post(this.baseUrl + 'userMetadata_create', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  getUserPermissions() {
    return this.http.get(this.baseUrl + 'userPermissions_get', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getOnlinePermission() {
    let sts;
    this.getUserPermissions().subscribe((res) => {
      sts = res['data']['condition']['online'];

    });

    if (sts == 'true') {
      return true;
    }
    else {
      return false;
    }
  }


  //servive hours

  // OpeningHours(e) {

  //   const formdata = new FormData();
  //   formdata.append('day', e.day);
  //   formdata.append('type', e.type);
  //   formdata.append('open_time', e.open);
  //   formdata.append('is_closed', e.closed ? '1' : '0');
  //   formdata.append('closing_time', e.close);


  //   return this.http.post(this.baseUrl + 'insert_service_hours', formdata, {
  //     headers: new HttpHeaders({
  //       Authorization: 'Bearer ' + this.authService.get().getValue(),
  //     }),
  //   });
  // }
  OpeningHours(e) {

    let val = {
      data: e
    }


    return this.http.post(this.baseUrl + 'add_service_hours', val, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  deteleServiceHoursGroup(id) {

    let data = {
      "group_id": id
    };
    return this.http.post(this.baseUrl + 'delete_service_hour', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });

  }

  getServiceHours() {

    return this.http.get(this.baseUrl + 'get_service_hours', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  editServiceHours(data) {

    return this.http.post(this.baseUrl + 'edit_service_hours', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  deleteServiceHours(id) {
    const formdata = new FormData();
    formdata.append('id', id);
    return this.http.post(this.baseUrl + 'delete_service_hours', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  addCordinates(lon, lat, addrs) {
    const formdata = new FormData();
    formdata.append('longitude', lon);
    formdata.append('latitude', lat);
    formdata.append('address', addrs);
    return this.http.post(this.baseUrl + 'addCoordinates', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }



  getCorrdinate() {

    return this.http.get(this.baseUrl + 'getCoordinates', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }








  getTableReservation() {
    return this.http.get(this.baseUrl + 'get_table_reservation', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  updateTableReservation(data) {
    let preorder = data.preOrder ? '1' : '0';
    let active = data.is_active ? '1' : '0';
    const formdata = new FormData;

    formdata.append('minimum_seat', data.minGuests);
    formdata.append('maximum_seat', data.maxGuests);
    formdata.append('waiting_time', data.minTime);
    formdata.append('reservation_time', data.holdTable);
    formdata.append('day_inadvance', data.maxTime);
    formdata.append('is_preorder', preorder);
    formdata.append('is_active', active);
    formdata.append('inactive_reason', data.inactive_reason);
    formdata.append('start_time_buffer', data.start_time_buffer);
    formdata.append('end_time_buffer', data.end_time_buffer);
    formdata.append('slot_duration', data.slot_duration);
    return this.http.post(this.baseUrl + 'add_table_reservation', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  getDeliveryZones() {
    return this.http.get(this.baseUrl + 'get_delivery_zone', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  addDeliveryZones(data) {
    const formdata = new FormData;

    formdata.append('name', data.name);
    formdata.append('zone_area', data.area);
    formdata.append('minimum_amount', data.min);
    formdata.append('delivery_fee', data.fee);
    formdata.append('type', data.type);
    formdata.append('order_outside', '0');



    return this.http.post(this.baseUrl + 'add_delivery_zone', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  updateDeliveryZones(id, data) {
    const formdata = new FormData;

    formdata.append('id', id);
    formdata.append('name', data.name);
    formdata.append('zone_area', data.area);
    formdata.append('minimum_amount', data.min);
    formdata.append('delivery_fee', data.fee);
    formdata.append('type', data.type);
    formdata.append('order_outside', '0');



    return this.http.post(this.baseUrl + 'update_delivery_zone', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  deleteDeliveryZone(id) {
    console.log(id);
    const formdata = new FormData;
    formdata.append('id', id);

    return this.http.post(this.baseUrl + 'delete_delivery_zone', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  /////////////////// Reports

  getTerminalReport(start, end) {
    // const start_date = moment(start).startOf(s).format('YYYY-MM-DD HH:mm:ss');
    // const end_date = moment(end).endOf(e).format('YYYY-MM-DD HH:mm:ss');

    // const start_date = moment(start).set({ "hour": stime.split(':')[0], "minute": stime.split(':')[1] }).format('YYYY-MM-DD HH:mm:ss');
    // const end_date = moment(end).set({ "hour": etime.split(':')[0], "minute": etime.split(':')[1] }).format('YYYY-MM-DD HH:mm:ss');

    return this.http.get(
      this.baseUrl +
      'terminal_report?from_date=' +
      moment(start).utc().format() +
      '&&to_date=' +
      moment(end).utc().format(),
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }


  getTerminalSummeryReport(start, end) {
   

   

    // let data={
    //   startDate: 1680307200000,
    //   endDate: 1726106002560
    // }
    let data={
      startDate: start,
      endDate: end
    }


    return this.http.post(
      this.baseUrl +
      'terminal_summary_report',data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }




  getTerminalReportUnified(data) {
   
    return this.http.post(
      this.baseUrl +
      'terminal_report_unified',data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }
  getAllPaymentRecordsByDate(start, end) {
    return this.http.get(
      this.baseUrl +
      'get_all_records_by_date?from_date=' +
      moment(start).utc().format() +
      '&&to_date=' +
      moment(end).utc().format(),
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }


  //Loyalty
  getAllLoyaltyCreditConfig() {
    return this.http.get(
      this.baseUrl +
      'restaurant-wallet-points-ratio-list' ,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }
  createLoyaltyCreditConfig(data) {
    return this.http.post(
      this.baseUrl +
      'restaurant-wallet-points-ratio-create',data ,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }
  getLoyaltyProducts() {
    return this.http.get(
      this.baseUrl +
      'loyalty_product_list',
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  updateLoyaltyProduct(data) {
    return this.http.post(
      this.baseUrl +
      'loyalty_product_update',data ,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }
  updateLoyaltyProductBulk(data) {
    return this.http.post(
      this.baseUrl +
      'loyalty_product_bulk_update',data ,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }


  updateLoyaltyCreditConfig(data,id) {
    return this.http.post(
      this.baseUrl +
      'restaurant-wallet-points-ratio-update/'+id,data ,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }
  deleteLoyaltyCreditConfig(id) {
    return this.http.get(
      this.baseUrl +
      'restaurant-wallet-points-ratio-delete/'+id,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }



   //Loyalty redumption 
   getAllLoyaltyRedumptionConfig() {
    return this.http.get(
      this.baseUrl +
      'restaurant-wallet-points-ratio-redumption-list' ,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }
  createLoyaltyRedumptionConfig(data) {
    return this.http.post(
      this.baseUrl +
      'restaurant-wallet-points-ratio-redumption-create',data ,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  updateLoyaltyRedumptionConfig(data,id) {
    return this.http.post(
      this.baseUrl +
      'restaurant-wallet-points-ratio-redumption-update/'+id,data ,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }
  deleteLoyaltyRedumptionConfig(id) {
    return this.http.get(
      this.baseUrl +
      'restaurant-wallet-points-ratio-redumption-delete/'+id,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }




  getTerminalReportByShift(from, to) {

    return this.http.get(
      this.baseUrl +
      'terminal_report_shift?from_date=' +
      from +
      '&&to_date=' +
      to,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  getSalesReport(start: Date, end: Date) {
    // const start_date = moment(start).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    // const end_date = moment(end).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    return this.http.get(
      this.baseUrl +
      'sales_report?from_date=' +
      moment(start).utc().format() +
      '&&to_date=' +
      moment(end).utc().format(),
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  getSalesReportCategory(start: Date, end: Date) {
    // const start_date = moment(start).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    // const end_date = moment(end).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    return this.http.get(
      this.baseUrl +
      'sales_report_category?from_date=' +
      moment(start).utc().format() +
      '&&to_date=' +
      moment(end).utc().format(),
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  itemSaleReportEmail(start: Date, end: Date, email: string) {
    // const start_date = moment(start).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    // const end_date = moment(end).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    const start_date = moment(start).utc().format();
    const end_date = moment(end).utc().format();
    let data = {
      'from_date': start_date,
      'to_date': end_date,
      'mailid': email
    };
    return this.http.post(
      this.baseUrl +
      'sales_report_category_pdf_email', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
        responseType: 'blob',
        observe: 'response',
      },
    )
  }
  itemSaleReportPdfExport(start: Date, end: Date) {
    // const start_date = moment(start).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    // const end_date = moment(end).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    const start_date = moment(start).utc().format();
    const end_date = moment(end).utc().format();
    let data = {
      'from_date': start_date,
      'to_date': end_date,
    };
    return this.http.post(
      this.baseUrl +
      'sales_report_category_pdf_export', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
        responseType: 'blob',
        observe: 'response',
      },
    )
  }


  
  getProductSalesByOrderType(type:any,start: any, end: any,aggregation:any) {
    
    console.log('check from',start);
    console.log('check to',end);

    const dateStringStart = start;
    const date = new Date(dateStringStart);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDateStart = `${year}-${month}-${day}`;

    const dateStringEnd = end;
    const dateend = new Date(dateStringEnd);
    const yearend = date.getFullYear();
    const monthend = String(dateend.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const dayend = String(dateend.getDate()).padStart(2, '0');
    const formattedDateEnd = `${yearend}-${monthend}-${dayend}`;
  

    return this.http.get(
      this.baseUrl +
      'product_sales_by_order_type?type='+type+'&from_date=' +
      formattedDateStart +
      '&to_date=' +
      formattedDateEnd+'&aggregation='+aggregation,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  getProductSalesByOrderTypeExport(type:any,start: any, end: any,aggregation:any) {
    const dateStringStart = start;
    const date = new Date(dateStringStart);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDateStart = `${year}-${month}-${day}`;

    const dateStringEnd = end;
    const dateend = new Date(dateStringEnd);
    const yearend = date.getFullYear();
    const monthend = String(dateend.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const dayend = String(dateend.getDate()).padStart(2, '0');
    const formattedDateEnd = `${yearend}-${monthend}-${dayend}`;
    return this.http.get(
      this.baseUrl +'product_sales_by_order_type?type='+type+'&from_date='+
      formattedDateStart +
      '&to_date='+
      formattedDateEnd+'&aggregation='+aggregation,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
        responseType: 'blob',
        observe: 'response',
      },
    );
  }

  getCustomerJournalExcelExportCustomer(start: any, end: any,cid:any) {
    return this.http.get(
      this.baseUrl +'customer_journal_excel_export?from_date='+
      moment(start).utc().format() +
      '&to_date='+
      moment(end).utc().format()+'&customer_id='+cid,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
        responseType: 'blob',
        observe: 'response',
      },
    );
  }

  getCustomerJournalExcelExport(start: any, end: any) {
    return this.http.get(
      this.baseUrl +'customer_journal_excel_export?from_date='+
      moment(start).utc().format() +
      '&to_date='+
      moment(end).utc().format(),
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
        responseType: 'blob',
        observe: 'response',
      },
    );
  }
  getCustomerJournalPdfExport(start: any, end: any) {
    return this.http.get(
      this.baseUrl +'customer_journal_pdf_export?from_date='+
      moment(start).utc().format() +
      '&to_date='+
      moment(end).utc().format(),
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
        responseType: 'blob',
        observe: 'response',
      },
    );
  }
  getCustomerJournalPdfExportrCustomer(start: any, end: any,cid:any) {
    return this.http.get(
      this.baseUrl +'customer_journal_pdf_export_by_cust_id?from_date='+
      moment(start).utc().format() +
      '&to_date='+
      moment(end).utc().format()+'&customer_id='+cid,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
        responseType: 'blob',
        observe: 'response',
      },
    );
  }

  


  getSalesReportModCount(start: Date, end: Date) {
    return this.http.get(
      this.baseUrl +
      'sales_report_mod_count?from_date=' +
      moment(start).utc().format() +
      '&&to_date=' +
      moment(end).utc().format(),
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  getSalesSummary(start: Date, end: any) {
    // const start_date = moment(start).set({ "hour": stime.split(':')[0], "minute": stime.split(':')[1] }).format('YYYY-MM-DD HH:mm:ss');
    // const end_date = moment(end).set({ "hour": etime.split(':')[0], "minute": etime.split(':')[1] }).format('YYYY-MM-DD HH:mm:ss');
    return this.http.get(
      this.baseUrl +
      'sales_summary?from_date=' +
      moment(start).utc().format() +
      '&&to_date=' +
      moment(end).utc().format(),
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }


  staffReport(start: Date, end: any) {
    return this.http.get(
      this.baseUrl +
      'staff_report?start_time=' +
      moment(start).utc().format() +
      '&&end_time=' +
      moment(end).utc().format(),
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  salesSummaryReportEmail(start: Date, end: any, email: any) {
    let payload = {
      from_date: moment(start).utc().format(),
      to_date: moment(end).utc().format(),
      email: email
    };
    return this.http.post(
      this.baseUrl +
      'sales_summary_report', payload,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }
  salesSummaryV2ReportEmail(start: any, end: any, email: any) {
    let payload = {
     "startDate": start,
    "endDate": end,
      "email": email
    };
    return this.http.post(
      this.baseUrl +
      'sales_summary_v2_pdf_report_send_mail', payload,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  customerTransactionReportEmail(start: Date, end: any, email: any, cus_id: any) {
    let payload = {
      from_date: moment(start).utc().format(),
      to_date: moment(end).utc().format(),
      email: email,
      customer_id: cus_id
    };
    return this.http.post(
      this.baseUrl +
      'customer_transaction_report', payload,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }
  salesSummaryReportPdfExport(start: any, end: any) {
    let payload = {
      from_date: moment(start).utc().format(),
      to_date: moment(end).utc().format()
    };
    return this.http.post(
      this.baseUrl +
      'sales_summary_report_pdf_export', payload,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
        responseType: 'blob',
        observe: 'response',
      },
    );
  }

  salesSummaryV2ReportPdfExport(start: any, end: any) {
    let payload = {
      "startDate": start,
    "endDate": end
    };
    return this.http.post(
      this.baseUrl +
      'sales_summary_v2_pdf_report_export', payload,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
        responseType: 'blob',
        observe: 'response',
      },
    );
  }
  customerTransactionReportPdfExport(start: Date, end: any, cus_id: any) {
    let payload = {
      from_date: moment(start).utc().format(),
      to_date: moment(end).utc().format(),
      customer_id: cus_id
    };
    return this.http.post(
      this.baseUrl +
      'customer_transaction_report_pdf_export', payload,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
        responseType: 'blob',
        observe: 'response',
      },
    );
  }

  getMasterReport(start: Date, end: any) {

    // const start_date = moment(start).set({ "hour": stime.split(':')[0], "minute": stime.split(':')[1] }).format('YYYY-MM-DD HH:mm:ss');
    // const end_date = moment(end).set({ "hour": etime.split(':')[0], "minute": etime.split(':')[1] }).format('YYYY-MM-DD HH:mm:ss');

    return this.http.get(
      this.baseUrl +
      'getPyamentRecords?from_date=' +
      moment(start).utc().format() +
      '&&to_date=' +
      moment(end).utc().format(),
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  getMasterReportMonth(index) {
    return this.http.get(
      this.baseUrl +
      'get_master_analytics_month?index=' + index,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  getMasterReportWeek(index) {
    return this.http.get(
      this.baseUrl +
      'get_master_analytics_week?index=' + index,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  getMasterReportWeekByDay(index, uid) {
    return this.http.get(
      this.baseUrl +
      'get_master_analytics_week_day?index=' + index + '&uid=' + uid,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }
  getMasterReportDay(index) {
    return this.http.get(
      this.baseUrl +
      'get_master_analytics_day?index=' + index,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  getSalesSummaryByShift(start: Date, end: any,) {
    return this.http.get(
      this.baseUrl +
      'sales_summary_by_shift?from_date=' +
      start +
      '&to_date=' +
      end,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  getSalesFeed(start: Date, end: Date) {
    // const start_date = moment(start).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    // const end_date = moment(end).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    return this.http.get(
      this.baseUrl +
      'sales_feed?from_date=' +
      moment(start).utc().format() +
      '&&to_date=' +
      moment(end).utc().format(),
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  salesTrendDaily(start: Date, end: Date) {
    // const start_date = moment(start).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    // const end_date = moment(end).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    let data = {
      from_date: moment(start).utc().format(),
      to_date: moment(end).utc().format()
    };
    return this.http.post(
      this.baseUrl +
      'sales_summary_daily', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }


  getDiscountReport(from_date, to_date) {
    // let f = '2022-02-01T18:30:00Z';
    // let y='2022-02-02T18:29:59Z';


    return this.http.get(this.baseUrl + 'discountReport?from_date=' + from_date + '&to_date=' + to_date, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  costReportByInventory() {
    return this.http.get(this.baseUrl + 'cost_report_by_inventory', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  costReportByCost() {
    return this.http.get(this.baseUrl + 'cost_report_by_cost', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  costReportBySale(start, end) {
    // const start_date = moment(start).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    // const end_date = moment(end).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    return this.http.get(
      this.baseUrl +
      'cost_report_by_sale?from_date=' +
      moment(start).utc().format() +
      '&&to_date=' +
      moment(end).utc().format(),
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  costReportBySaleHistory() {
    return this.http.get(
      this.baseUrl +
      'cost_report_by_sale_history',
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }



  /////////////////// For Dropdowns

  getData(endPoint: string) {
    return this.http.get(this.baseUrl + endPoint, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  /////////////////// Category

  createCategories(name, catDescription, catActive, inactiveReason, catHidden, image, showonly, exclude_online_table_menu,availability,color,course=null) {

    console.log('availability :',availability);
    console.log('availability  json:',JSON.stringify(availability));

    const formdata = new FormData();
    formdata.append('name', name);
    formdata.append('image', image);
    formdata.append('cat_description', catDescription);
    formdata.append('hide_from_menu', catHidden ? '1' : '0');
    formdata.append('inactive', catActive ? '1' : '0');
    formdata.append('inactive_reason', inactiveReason);
    formdata.append('show_only', showonly ? '1' : '0');
    formdata.append('exclude_online_table_menu', exclude_online_table_menu ? '1' : '0');
    formdata.append('availability', JSON.stringify(availability) );
    formdata.append('color', color);
    formdata.append('course', course);
    return this.http.post(this.baseUrl + 'category_add', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  editCategories(id, name, catDescription, catActive, inactiveReason, catHidden, image, showonly, exclude_online_table_menu,availability,color,course=null) {

    // console.log('availability :',availability);
    // console.log('availability  json:',JSON.stringify(availability));
    const formdata = new FormData();
    formdata.append('id', id);
    formdata.append('name', name);
    formdata.append('image', image);
    formdata.append('cat_description', catDescription);
    formdata.append('hide_from_menu', catHidden ? '1' : '0');
    formdata.append('inactive', catActive ? '1' : '0');
    formdata.append('inactive_reason', inactiveReason);
    formdata.append('show_only', showonly ? '1' : '0');
    formdata.append('exclude_online_table_menu', exclude_online_table_menu ? '1' : '0');
    formdata.append('availability', JSON.stringify(availability) );
    formdata.append('course', course);
    formdata.append('color', color);

    return this.http.post(this.baseUrl + 'category_edit', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });

  }




  getCategories() {
    return this.http.get(this.baseUrl + 'categoryp', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  getAllCategories() {
    return this.http.get(this.baseUrl + 'category_all_get', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  addCategoryAvailability(data) {


    return this.http.post(this.baseUrl + 'add_cat_availability', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  deleteCategoryAvailability(id) {
    const formdata = new FormData();
    formdata.append('group_id', id);



    return this.http.post(this.baseUrl + 'cat_availability_delete', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getCategoryAvailability(id) {

    return this.http.get(this.baseUrl + 'get_cat_availability?cat_id=' + id, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getCuisine() {
    return this.http.get(this.baseUrl + 'get_cuisine', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  addCuisine(name, image) {
    const formdata = new FormData();
    formdata.append('name', name);
    formdata.append('image', image);

    return this.http.post(this.baseUrl + 'add_cuisine', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  deleteCuisine(id) {
    const formdata = new FormData();
    formdata.append('id', id);
    return this.http.post(this.baseUrl + 'delete_cuisine', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  getCuisineMapping() {
    return this.http.get(this.baseUrl + 'get_cuisine_user_map', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  addCuisineMapping(id) {
    const formdata = new FormData();
    formdata.append('cuisine_id', id);
    return this.http.post(this.baseUrl + 'add_cuisine_user_map', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  deleteCuisineMapping(id) {
    const formdata = new FormData();
    formdata.append('id', id);
    return this.http.post(this.baseUrl + 'delete_cuisine_user_map', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }






  deleteCategory(id) {
    const formdata = new FormData();
    formdata.append('id', id);
    return this.http.post(this.baseUrl + 'category_delete', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  //get-category-mapped

  getCategoryMappedEntities(id) {
    let payload = {
      'cat_id': id
    };
    return this.http.post(this.baseUrl + 'get-category-mapped', payload, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  getModifierCategoryMappedEntities(id) {
    let payload = {
      'modifier_cat_id': id
    };
    return this.http.post(this.baseUrl + 'get-modf-cat-to-prod-cat', payload, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  getModifierMappedEntities(id) {
    let payload = {
      'modifier_id': id
    };
    return this.http.post(this.baseUrl + 'get-modifier-to-product-mapping', payload, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getComboCatMappedEntities(id) {
    let payload = {
      "combo_cat_id": id
    }
    return this.http.post(this.baseUrl + 'get_combo_cat_mapped_data', payload, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  deleteModifierCategoryMappedEntities(id) {

    let payload = {
      'modifier_cat_id': id
    };
    return this.http.post(this.baseUrl + 'delete-modf-cat-to-prod-cat', payload, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  deleteModifierMappedEntities(id) {
    let payload = {
      'modifier_id': id
    };
    return this.http.post(this.baseUrl + 'delete-modifier-to-product-mapping', payload, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  deleteCategoryWithMappedEntities(id) {
    const formdata = new FormData();
    formdata.append('cat_id', id);
    return this.http.post(this.baseUrl + 'delete-category-mapped', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  //////////////// Subcategory

  createSubCategories(name: any, category_id,color='#ffffff',primary_course_id=null) {
    const formdata = new FormData();
    formdata.append('category_id', category_id);
    formdata.append('name', name);
    formdata.append('color', color);
    formdata.append('primary_course_id', primary_course_id);
    return this.http.post(this.baseUrl + 'subcategory_add', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getSubCategories() {
    return this.http.get(this.baseUrl + 'subcategoryp', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getSubCategoriesById(id: any) {
    return this.http.get(this.baseUrl + 'subcategory_by_category?cat_id=' + id, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }



  editSubCategories(id: any, name: any, cat_id,color='#ffffff',primary_course_id=null) {
    const formdata = new FormData();
    formdata.append('id', id);
    formdata.append('cat_id', cat_id);
    formdata.append('name', name);
    formdata.append('color', color);
    formdata.append('primary_course_id', primary_course_id);
    return this.http.post(this.baseUrl + 'subcategory_edit', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  deleteSubCategory(id: any) {
    const formdata = new FormData();
    formdata.append('id', id);
    return this.http.post(this.baseUrl + 'subcategory_delete', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  ///////////////////// Modifiercategory

  createModifierCategories(name, is_mandatory: any, min_modifier: any, max_modifier: any, allow_same: any, hide_online: any) {
    const formdata = new FormData();
    formdata.append('name', name);
    formdata.append('is_mandatory', is_mandatory ? '1' : '0');
    formdata.append('min_modifier', min_modifier);
    formdata.append('max_modifier', max_modifier);
    formdata.append('allow_same', allow_same ? '1' : '0');
    formdata.append('hide_online', hide_online ? '1' : '0');
    return this.http.post(this.baseUrl + 'modifierscategory_add', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  duplicateModifierCategories(id) {
    const formdata = new FormData();
    formdata.append('id', id);
  
    return this.http.post(this.baseUrl + 'modifierscategory_duplicate', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getModifierCategories() {
    return this.http.get(this.baseUrl + 'modifierscategoryp', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  editModifierCategories(id: any, name: any, is_mandatory: any, min_modifier: any, max_modifier: any, allow_same: any, hide_online: any) {
    const formdata = new FormData();
    formdata.append('id', id);
    formdata.append('name', name);
    formdata.append('is_mandatory', is_mandatory ? '1' : '0');
    formdata.append('min_modifier', min_modifier);
    formdata.append('max_modifier', max_modifier);
    formdata.append('allow_same', allow_same ? '1' : '0');
    formdata.append('hide_online', hide_online ? '1' : '0');
    return this.http.post(this.baseUrl + 'modifierscategory_edit', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  deleteModifierCategory(id: any) {
    const formdata = new FormData();
    formdata.append('id', id);
    return this.http.post(this.baseUrl + 'modifierscategory_delete', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  deleteModifierCategoryByProductId(id: any) {
    const formdata = new FormData();
    formdata.append('category_id', id);
    return this.http.post(this.baseUrl + 'modifiers_delete_by_category', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }




  //////////////// Modifiers

  createModifiers(name: any, price, cost, plus, category_id, hide_online, preselect, color, is_alert, alert_text, image = null) {
    console.log(`Check point'`, image);

    const formdata = new FormData();
    formdata.append('cat_id', category_id);
    formdata.append('name', name);
    formdata.append('price', price);
    formdata.append('cost', cost);
    formdata.append('plus', plus ? '1' : '0');
    formdata.append('hide_online', hide_online ? '1' : '0');
    formdata.append('preselect', preselect ? '1' : '0');
    formdata.append('color', color);
    formdata.append('is_alert', is_alert ? '1' : '0');
    formdata.append('alert_text', alert_text);
    if (image) {
      formdata.append('image_id', image);
    }
    return this.http.post(this.baseUrl + 'modifiers_add', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getModifiers() {
    return this.http.get(this.baseUrl + 'modifiersp', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  editModifiers(id: any, name: any, price, cost, plus, cat_id, hide_online, color, preselect, is_alert, alert_text, image_id = null,) {
    const formdata = new FormData();
    formdata.append('id', id);
    formdata.append('name', name);
    formdata.append('cat_id', cat_id);
    formdata.append('price', price);
    formdata.append('cost', cost);
    formdata.append('plus', plus ? '1' : '0');
    formdata.append('hide_online', hide_online ? '1' : '0');
    formdata.append('color', color);
    formdata.append('preselect', preselect ? '1' : '0');

    if (image_id != null) {
      formdata.append('image_id', image_id);
    }
    formdata.append('is_alert', is_alert ? '1' : '0');
    formdata.append('alert_text', alert_text);
    return this.http.post(this.baseUrl + 'modifiers_edit', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  deleteModifier(id: any) {
    const formdata = new FormData();
    formdata.append('id', id);
    return this.http.post(this.baseUrl + 'modifiers_delete', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  createUpdateModifierToProductsMapping(data: any) {
    const formdata = new FormData();
    formdata.append('data', data);
    return this.http.post(this.baseUrl + 'modifier_map_to_products', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  createUpdateModifierCatToProductsMapping(data: any) {
    const formdata = new FormData();
    formdata.append('data', data);
    return this.http.post(this.baseUrl + 'add_prod_modcat_mapping_bulk', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getModifierToProductsMapping(id: any) {
    return this.http.get(this.baseUrl + 'modifier_map_to_products?id=' + id, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  /////////////////// Price Tags

  createPricetags(name) {
    const formdata = new FormData();
    formdata.append('name', name);
    return this.http.post(this.baseUrl + 'pricetag_add', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  createPricetag(name,automatic,availability) {
    const formdata = new FormData();
    formdata.append('name', name);
    formdata.append('automatic', automatic);
    formdata.append('availability', JSON.stringify(availability));
    return this.http.post(this.baseUrl + 'pricetag_add', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  editPricetags(id, name) {
    const formdata = new FormData();
    formdata.append('id', id);
    formdata.append('name', name);
    return this.http.post(this.baseUrl + 'pricetag_edit', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  editPricetag(id, name,automatic,availability) {
    const formdata = new FormData();
    formdata.append('id', id);
    formdata.append('name', name);
    formdata.append('automatic', automatic);
    formdata.append('availability', JSON.stringify(availability));
    return this.http.post(this.baseUrl + 'pricetag_edit', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getPricetags() {
    return this.http.get(this.baseUrl + 'pricetagp', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  deletepricetag(id) {
    const formdata = new FormData();
    formdata.append('id', id);
    return this.http.post(this.baseUrl + 'pricetag_delete', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  /////////////////// Table Groups

  createTableGroups(name, image_background) {
    const formdata = new FormData();
    formdata.append('name', name);
    if(image_background){
    formdata.append('image_background', image_background);
    }
    return this.http.post(this.baseUrl + 'tablegroup_add', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  editTableGroups(id, name, image_background) {
    const formdata = new FormData();
    formdata.append('id', id);
    formdata.append('name', name);
    formdata.append('image_background', image_background);
    return this.http.post(this.baseUrl + 'tablegroup_edit', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getTableGroups() {
    return this.http.get(this.baseUrl + 'tablegroupp', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  deleteTableGroup(id) {
    const formdata = new FormData();
    formdata.append('id', id);
    return this.http.post(this.baseUrl + 'tablegroup_delete', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  //courses

  getCourses() {
    return this.http.get(this.baseUrl + 'courses', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  createCourses(name,position,pricetag) {
    const formdata = new FormData();
    formdata.append('name', name);
    formdata.append('position', position);
    if (pricetag !== null && pricetag !== '') {
      formdata.append('price_tag', pricetag);
    }
    
    return this.http.post(this.baseUrl + 'courses', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  updateCourses(id,name,position,pricetag) {
    const formdata = new FormData();
    formdata.append('id', id);
    formdata.append('name', name);
    formdata.append('position', position);
    
    if (pricetag !== null && pricetag !== '') {

      formdata.append('price_tag', pricetag);
    }
  
    return this.http.post(this.baseUrl + 'course_update', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  

  deleteCourse(id) {
   
    return this.http.delete(this.baseUrl + 'courses/' + id, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  /////////////////// VoidReasons

  
  createVoidReasons(name) {
    const formdata = new FormData();
    formdata.append('name', name);
    return this.http.post(this.baseUrl + 'voidreason_add', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  editVoidReasons(id, name) {
    const formdata = new FormData();
    formdata.append('id', id);
    formdata.append('name', name);
    return this.http.post(this.baseUrl + 'voidreason_edit', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getVoidReasons() {
    return this.http.get(this.baseUrl + 'voidreasonp', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  deleteVoidReason(id) {
    const formdata = new FormData();
    formdata.append('id', id);
    return this.http.post(this.baseUrl + 'voidreason_delete', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  /////////////////// Discounts

  createDiscounts(payload) {

    return this.http.post(this.baseUrl + 'discount_add', payload, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  editDiscounts(payload) {

    return this.http.post(this.baseUrl + 'discount_edit', payload, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getDiscounts() {
    return this.http.get(this.baseUrl + 'discountp', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  deleteDiscount(id) {
    const formdata = new FormData();
    formdata.append('id', id);
    return this.http.post(this.baseUrl + 'discount_delete', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  /////////////////// Paymenttypes

  createPaymentTypes(name, online, table, self, pos, del, pick) {
    const formdata = new FormData();
    formdata.append('name', name);
    formdata.append('is_online', online == true || online == 1 ? '1' : '0');
    formdata.append('is_table', table == true || table == 1 ? '1' : '0');
    formdata.append('is_selfkiosk', self == true || self == 1 ? '1' : '0');
    formdata.append('is_pos', pos == true || pos == 1 ? '1' : '0');
    formdata.append('is_online_delivery', del == true || del == 1 ? '1' : '0');
    formdata.append('is_online_pickup', pick == true || pick == 1 ? '1' : '0');
    return this.http.post(this.baseUrl + 'paymenttype_add', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  editPaymentTypes(id, name, online, table, self, pos, del, pick) {
    const formdata = new FormData();
    formdata.append('id', id);
    formdata.append('name', name);
    formdata.append('is_online', online == true || online == 1 ? '1' : '0');
    formdata.append('is_table', table == true || table == 1 ? '1' : '0');
    formdata.append('is_selfkiosk', self == true || self == 1 ? '1' : '0');
    formdata.append('is_pos', pos == true || pos == 1 ? '1' : '0');
    formdata.append('is_online_delivery', del == true || del == 1 ? '1' : '0');
    formdata.append('is_online_pickup', pick == true || pick == 1 ? '1' : '0');
    return this.http.post(this.baseUrl + 'paymenttype_edit', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getPaymentTypes() {
    return this.http.get(this.baseUrl + 'paymenttypep', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  deletePaymentType(id) {
    const formdata = new FormData();
    formdata.append('id', id);
    return this.http.post(this.baseUrl + 'paymenttype_delete', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  /////////////////// ModifierMapping

  createModifierMappings(productCat, modifierCat) {

    const formdata = new FormData();
    formdata.append('category_id', productCat);
    formdata.append('modifierscategory_id', modifierCat);
    formdata.append('forced_count', '1');
    return this.http.post(
      this.baseUrl + 'productmodifiersmapping_add',
      formdata,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  editModifierMappings(id, productCat, modifierCat) {
    const formdata = new FormData();
    formdata.append('id', id);
    formdata.append('category_id', productCat);
    formdata.append('modifierscategory_id', modifierCat);
    formdata.append('forced_count', '1');
    return this.http.post(
      this.baseUrl + 'productmodifiersmapping_edit',
      formdata,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  getModifierMappings() {
    return this.http.get(this.baseUrl + 'productmodifiersmappingp', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  getModifierMappingsAll() {
    return this.http.get(this.baseUrl + 'productmodifiersmapping_all', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getModifiersMappedToProductByAll(id) {
    return this.http.get(this.baseUrl + 'modifiers_of_product?id=' + id, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  deleteModifierMapping(id) {
    const formdata = new FormData();
    formdata.append('id', id);
    return this.http.post(
      this.baseUrl + 'productmodifiersmapping_delete',
      formdata,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  /////////////////// Tables

  createTables(name, seat, alert, group,position) {
    const formdata = new FormData();
    formdata.append('name', name);
    formdata.append('total_seat_count', seat);
    formdata.append('alerttime', alert);
    formdata.append('tablegroup_id', group);
    formdata.append('position', position);
    return this.http.post(this.baseUrl + 'table_add', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  editTables(id, name, seat, alert, group) {
    const formdata = new FormData();
    formdata.append('id', id);
    formdata.append('name', name);
    formdata.append('total_seat_count', seat);
    formdata.append('alerttime', alert);
    formdata.append('tablegroup_id', group);
    return this.http.post(this.baseUrl + 'table_edit', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getTables() {
    return this.http.get(this.baseUrl + 'tablep', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  deleteTables(id) {
    const formdata = new FormData();
    formdata.append('id', id);
    return this.http.post(this.baseUrl + 'table_delete', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  //////////////////// Staff

  getStaffs() {
    return this.http.get(this.baseUrl + 'staffp', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getStaffById(id) {
    return this.http.get(this.baseUrl + 'staff_by_id?id=' + id, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  createStaff(name: any, pin: any, role: any) {
    const formdata = new FormData();
    formdata.append('name', name);
    formdata.append('pin', pin);
    formdata.append('role_id', role);
    return this.http.post(this.baseUrl + 'staff_add', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  editStaff(id: any, name: any, pin: any, role: any) {
    const formdata = new FormData();
    formdata.append('id', id);
    formdata.append('name', name);
    formdata.append('pin', pin);
    formdata.append('role_id', role);
    return this.http.post(this.baseUrl + 'staff_edit', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  deleteStaff(id: any) {
    const formdata = new FormData();
    formdata.append('id', id);
    return this.http.post(this.baseUrl + 'staff_delete', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  sendPushNotification(data: any) {
    const formdata = new FormData();
    formdata.append('title', data.title);
    formdata.append('body', data.body);
    return this.http.post(this.baseUrl + 'push_notification_to_app', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  //////////////// Products

  createProducts(add: any, image: any) {
    console.log(add.size);
    console.log(JSON.stringify(add.size));

    let expdate = moment(add.expiry_date).format()

    const formdata = new FormData();
    formdata.append('category_id', add.category_id);
    formdata.append('subcategory_id', add.subcategory_id);
    formdata.append('name', add.name);
    formdata.append('description', add.description);
    formdata.append('code', add.code);
    formdata.append('sale_price', add.sale_price);
    formdata.append('availability', JSON.stringify(add.availability));
    formdata.append('color', add.color);
    formdata.append('vat_id', '0');
    formdata.append('askprice', add.askprice ? '1' : '0');
    formdata.append('askqty', add.askqty ? '1' : '0');
    formdata.append('kitchennote', add.kitchennote ? '1' : '0');
    formdata.append('weight', add.weight ? '1' : '0');
    formdata.append('discountable', add.discountable ? '1' : '0');
    formdata.append('is_inventory', add.is_inventory ? '1' : '0');
    formdata.append('stock', add.stock);
    formdata.append('stock_unit', add.stock_unit);
    formdata.append('cost', add.cost);
    formdata.append('pricetag', JSON.stringify(add.pricetag));
    formdata.append('size', JSON.stringify(add.size));
    formdata.append('banner', add.banner);
    formdata.append('show_only', add.showOnly ? '1' : '0');
    formdata.append('is_inactive', add.is_inactive ? '1' : '0');
    formdata.append('inactive_reason', add.inactive_reason);
    formdata.append('special_instruction_hide', add.special_instruction_hide ? '1' : '0');
    formdata.append('is_hidden', add.is_hidden ? '1' : '0');
    formdata.append('expiry_date', expdate);
    formdata.append('short_name', add.short_name);
    formdata.append('table_price', add.table_price != null ? add.table_price :add.sale_price);
    formdata.append('hidden_online_table_menu', add.hidden_online_table_menu ? '1' : '0');
    formdata.append('is_loyalty_product', add.is_loyalty_product ? '1' : '0');

    if (image != '') {
      formdata.append('photo', image);
    }
    return this.http.post(this.baseUrl + 'product_add', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getProducts() {
    return this.http.get(this.baseUrl + 'productpq', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  getProductNames(data) {
    return this.http.post(this.baseUrl + 'product_names',data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  getCategoryNames(data) {
    return this.http.post(this.baseUrl + 'category_names',data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getAllProducts() {
    return this.http.get(this.baseUrl + 'getAllProduct', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  productDataSync() {
    return this.http.get(this.baseUrl + 'productDataRefresh', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getProductsBysub(cat, sub) {
    return this.http.get(this.baseUrl + 'get_product_sub?category_id=' + cat + '&subcategory_id=' + sub, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  getSubbyCat(cat) {
    return this.http.get(this.baseUrl + 'get_sub_by_cat?category_id=' + cat, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  editProducts(id: any, add: any, image: any) {


    let expdate = moment(add.expiry_date).format()
    const formdata = new FormData();
    formdata.append('id', id);
    formdata.append('category_id', add.category_id);
    formdata.append('subcategory_id', add.subcategory_id);
    formdata.append('availability', JSON.stringify(add.availability));
    formdata.append('description', add.description);
    formdata.append('name', add.name);
    formdata.append('code', add.code);
    formdata.append('sale_price', add.sale_price);
    formdata.append('color', add.color);
    formdata.append('vat_id', '0');
    formdata.append('askprice', add.askprice ? '1' : '0');
    formdata.append('askqty', add.askqty ? '1' : '0');
    formdata.append('kitchennote', add.kitchennote ? '1' : '0');
    formdata.append('weight', add.weight ? '1' : '0');
    formdata.append('discountable', add.discountable ? '1' : '0');
    formdata.append('is_inventory', add.is_inventory ? '1' : '0');
    formdata.append('stock', add.stock);
    formdata.append('stock_unit', add.stock_unit);
    formdata.append('cost', add.cost);
    formdata.append('pricetag', JSON.stringify(add.pricetag));
    formdata.append('size', JSON.stringify(add.size));
    formdata.append('banner', add.banner);
    formdata.append('show_only', add.showOnly ? '1' : '0');
    formdata.append('is_inactive', add.is_inactive ? '1' : '0');
    formdata.append('inactive_reason', add.inactive_reason);
    formdata.append('special_instruction_hide', add.special_instruction_hide ? '1' : '0');
    formdata.append('is_hidden', add.is_hidden ? '1' : '0');
    formdata.append('expiry_date', expdate);
    formdata.append('short_name', add.short_name);
    formdata.append('table_price', add.table_price != null ? add.table_price :add.sale_price);
    formdata.append('hidden_online_table_menu', add.hidden_online_table_menu ? '1' : '0');
    formdata.append('is_loyalty_product', add.is_loyalty_product ? '1' : '0');
    if (image != null) {
      formdata.append('image', image);
    }
    return this.http.post(this.baseUrl + 'product_edit', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  deleteProduct(id: any) {
    const formdata = new FormData();
    formdata.append('id', id);
    return this.http.post(this.baseUrl + 'product_delete', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  hideProduct(id: any, hidden: any) {
    const formdata = new FormData();
    formdata.append('id', id);
    formdata.append('is_hidden', hidden);
    return this.http.post(this.baseUrl + 'product_hidden', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  hideProductSize(id: any, hidden: any) {
    const formdata = new FormData();
    formdata.append('id', id);
    formdata.append('is_hidden', hidden);
    return this.http.post(this.baseUrl + 'productsize_hide_menu', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  inactiveProduct(id: any, inactive: any) {
    const formdata = new FormData();
    formdata.append('id', id);
    formdata.append('is_inactive', inactive);
    return this.http.post(this.baseUrl + 'product_inactive', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getDeliveryIntegration() {
    return this.http.get(this.baseUrl + 'get_delivery_integration', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  createDeliveryIntegration(data:any) {
    return this.http.post(this.baseUrl + 'create_delivery_integration', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  
  deleteDeliveryIntegration(data:any) {
    return this.http.post(this.baseUrl + 'delete_delivery_integration', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  productSizeDelete(id) {
    const formdata = new FormData();
    formdata.append('id', id);
    return this.http.post(this.baseUrl + 'productsize_delete', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  productSizeEdit(data: any) {
    const formdata = new FormData();
    formdata.append('id', data?.id);
    formdata.append('size_name', data?.size_name);
    formdata.append('size_amount', data?.size_amount);
    formdata.append('multiplier', data?.multiplier);
    formdata.append('position', data?.position);
    let availablemenu = JSON.stringify(data?.available_menu);
    formdata.append('available_menu', availablemenu);
    return this.http.post(this.baseUrl + 'productsize_edit', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  productImageRemove(id) {

    return this.http.get(this.baseUrl + 'product_image_null?id=' + id, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }





  addProductAvailability(id, data) {
    const formdata = new FormData();
    formdata.append('product_id', id);
    formdata.append('day', data.day);
    formdata.append('is_active', data.active ? '1' : '0')
    formdata.append('start', data.start);
    formdata.append('end', data.end);

    return this.http.post(this.baseUrl + 'add_prod_availability', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  deleteProductAvailability(id) {
    const formdata = new FormData();
    formdata.append('id', id);



    return this.http.post(this.baseUrl + 'delete_prod_availability', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getProductAvailability(id) {

    return this.http.get(this.baseUrl + 'get_prod_availability?product_id=' + id, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }




  categoryImageRemove(id) {

    return this.http.get(this.baseUrl + 'category_image_null?id=' + id, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }




  getProductTags(id) {
    console.log("id", id);

    return this.http.get(this.baseUrl + 'product_tags_get?product_id=' + id, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });

  }

  addProductTags(name, id) {
    const formdata = new FormData();
    formdata.append('product_id', id);
    formdata.append('tag', name);
    console.log(formdata);

    return this.http.post(this.baseUrl + 'product_tags_import', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });

  }

  deleteProductTags(id) {
    const formdata = new FormData();
    formdata.append('id', id);
    return this.http.post(this.baseUrl + 'product_tags_delete', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });

  }


  ///////////////////////// Roles

  getroles() {
    return this.http.get(this.baseUrl + 'rolep', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  createRoles(add: any,admin_permissions:any) {
    const formdata = new FormData();
    formdata.append('name', add.name);
    formdata.append('permissions', JSON.stringify(add.permissions));
    formdata.append('admin_permissions', JSON.stringify(admin_permissions));
    return this.http.post(this.baseUrl + 'role_add', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  editRoles(id: any, add: any, admin_permissions) {
    const formdata = new FormData();
    formdata.append('id', id);
    formdata.append('name', add.name);
    formdata.append('permissions', JSON.stringify(add.permissions));
    formdata.append('admin_permissions', JSON.stringify(admin_permissions));
    return this.http.post(this.baseUrl + 'role_edit', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  deleteRole(id: any) {
    const formdata = new FormData();
    formdata.append('id', id);
    return this.http.post(this.baseUrl + 'role_delete', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  /////////////////// Inventory

  createInventory(name, value, percent) {
    const formdata = new FormData();
    formdata.append('name', name);
    formdata.append('percentage', percent ? '1' : '0');
    formdata.append('value', value);
    return this.http.post(this.baseUrl + 'discount_add', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  editInventory(id, name, value, percent) {
    const formdata = new FormData();
    formdata.append('id', id);
    formdata.append('name', name);
    formdata.append('percentage', percent ? '1' : '0');
    formdata.append('value', value);
    return this.http.post(this.baseUrl + 'discount_edit', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getInventory() {
    return this.http.get(this.baseUrl + 'inventoryp', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  createProductInventory(data) {
    return this.http.post(this.baseUrl + 'create_product_inventory', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  getProductInventory(id: any) {
    return this.http.get(this.baseUrl + 'get_product_inventory?product_id=' + id, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getInventoryOrderingLevel() {
    return this.http.get(this.baseUrl + 'get_ordering_level', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  updateInventoryOrderingLevel(data:any) {
    return this.http.post(this.baseUrl + 'update_ordering_level',data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  getProductInventoryAll() {
    return this.http.get(this.baseUrl + 'get_product_inventory_all', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  deleteInventory(id) {
    const formdata = new FormData();
    formdata.append('id', id);
    return this.http.post(this.baseUrl + 'discount_delete', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  /// inventory category

  getInventoryCategories() {
    return this.http.get(this.baseUrl + 'inventory_category_get', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  getInventoryCategoriesByInventoryId(id) {
    let data = {
      id: id
    };
    return this.http.post(this.baseUrl + 'inventory_category_get_by_id', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  createInventoryCategory(data: any) {
    return this.http.post(this.baseUrl + 'inventory_category_add', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  updateInventoryCategory(data: any) {
    return this.http.post(this.baseUrl + 'inventory_category_update', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  deleteInventoryCategory(data: any) {
    return this.http.post(this.baseUrl + 'inventory_category_delete', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }



// door dash delivery

  listDoordashDeliveryStore(){
    return this.http.get(this.baseUrl + 'list_stores_doordash',{
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  createDoordashDeliveryStore(data){
    return this.http.post(this.baseUrl + 'create_store_doordash',data,{
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  createDoordashDeliveryBusiness(data){
    return this.http.post(this.baseUrl + 'create_business_doordash',data,{
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  updateDoordashDeliveryStore(data){
    return this.http.post(this.baseUrl + 'update_store_doordash',data,{
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  ///////////////////////////////////export xlsx

  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';

  generateExcel(jsonData: any[], fileName: string): void {
    //console.log(jsonData);
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName);
  }
  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.fileType });
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }


  ////////////////////////// sort

  //category sort

  getCategoriesSort() {
    return this.http.get(this.baseUrl + 'category_sort', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  saveCategoriesSort(data) {
    return this.http.post(this.baseUrl + 'category_sort', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  savePosTableSort(data) {
    return this.http.post(this.baseUrl + 'table_sort_save', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  saveProductSubSort(data) {
    return this.http.post(this.baseUrl + 'productsort', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  getSubCategoriesSort() {
    return this.http.get(this.baseUrl + 'subcategory_sort', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  saveSubCategoriesSort(data) {
    return this.http.post(this.baseUrl + 'subcategory_sort', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  //modifier category sort


  getModCategoriesSort() {
    return this.http.get(this.baseUrl + 'modifierscategory_sort', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  saveModCategoriesSort(data) {
    return this.http.post(this.baseUrl + 'modifierscategory_sort', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  //modifiers sort

  getModByCat(id) {
    return this.http.get(this.baseUrl + 'getModyfiersByCategory?mcatid=' + id, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  modifiersSort(data) {
    return this.http.post(this.baseUrl + 'modifiers_sort', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }






  //////////////////inventory

  getAllInventory() {
    return this.http.get(this.baseUrl + 'inventory_all', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      })
    })
  }
  getAllInventoryWithMaster() {
    return this.http.get(this.baseUrl + 'inventory_all_with_master', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      })
    })
  }

  getMappedStock(product_id) {

    const formdata = new FormData();
    formdata.append('product_id', product_id);

    return this.http.post(this.baseUrl + 'get_mapped_stock', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  inventoryEdit(id, data, pro_id, is_pro, lastupdate_flag) {
    const formdata = new FormData();
    formdata.append('id', id);
    formdata.append('alert', data['alert']);
    formdata.append('code', data['code']);
    formdata.append('pack_count', data['pack']);
    formdata.append('name', data['productname']);
    formdata.append('stock', data['stock']);
    formdata.append('ordering_level', data['ordering_level'] ? data['ordering_level'] : '');
    formdata.append('cat_id', data['cat_id'] ? data['cat_id'] : '');
    formdata.append('stock_type', data['stocktype']);
    formdata.append('stock_unit', data['stockunit']);
    formdata.append('cost', data['cost']);
    formdata.append('is_product', is_pro);
    formdata.append('product_id', pro_id);
    formdata.append('stock_last_update', lastupdate_flag);
    formdata.append('is_daily', data['daily'] ? '1' : '0');
    formdata.append('is_weekly', data['weekly'] ? '1' : '0');
    formdata.append('is_monthly', data['monthly'] ? '1' : '0');
    formdata.append('is_fortnightly', data['fortnightly'] ? '1' : '0');
    console.log("check last ", lastupdate_flag);
    return this.http.post(this.baseUrl + 'inventory_edit', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  inventoryDelete(id) {
    const formdata = new FormData();
    formdata.append('id', id);
    return this.http.post(this.baseUrl + 'inventory_delete', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  inventoryConsumption() {
    return this.http.get(this.baseUrl + 'inventory_consumption', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  





  addInventory(name, code, stock, stock_unit, cost, stock_type, pack_count, alert, daily, weekly, monthly, fortnightly, ordering_level, cat_id) {
    const formdata = new FormData();
    formdata.append('name', name);
    formdata.append('code', code);
    formdata.append('stock', stock);
    formdata.append('stock_unit', stock_unit);
    formdata.append('cost', cost);
    formdata.append('stock_type', stock_type);
    formdata.append('pack_count', pack_count);
    formdata.append('is_product', '0');
    formdata.append('product_id', '0');
    formdata.append('alert', alert);
    formdata.append('is_daily', daily ? '1' : '0');
    formdata.append('is_weekly', weekly ? '1' : '0');
    formdata.append('is_monthly', monthly ? '1' : '0');
    formdata.append('is_fortnightly', fortnightly ? '1' : '0');
    formdata.append('ordering_level', ordering_level);
    formdata.append('cat_id', cat_id);

    return this.http.post(this.baseUrl + 'add_inventoryItem', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });

  }

  productInventoryMapping(product_id, inventory_id, mapping_weight,product_size_id=null) {
    const formdata = new FormData();
    formdata.append('product_id', product_id);
    formdata.append('inventory_id', inventory_id);
    if (product_size_id !== null) { // Only append if product_size_id is not null
      formdata.append('product_size_id', product_size_id);
  }
    formdata.append('mapping_weight', mapping_weight);
    return this.http.post(this.baseUrl + 'product_inventory_mapping', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  
  productInventoryMappingEdit(mapping_id,product_id, inventory_id, mapping_weight) {
    const formdata = new FormData();
    formdata.append('id', mapping_id);
    formdata.append('product_id', product_id);
    formdata.append('inventory_id', inventory_id);
    formdata.append('mapping_weight', mapping_weight);
    return this.http.post(this.baseUrl + 'product_inventory_mapping_edit', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  productInventoryMappingUpdate(mapping_id,product_id, inventory_id, mapping_weight,product_size_id=null) {
    const formdata = new FormData();
    formdata.append('id', mapping_id);
    formdata.append('product_id', product_id);
    formdata.append('inventory_id', inventory_id);
    if (product_size_id !== null) { // Only append if product_size_id is not null
      formdata.append('product_size_id', product_size_id);
  }
    formdata.append('mapping_weight', mapping_weight);
    return this.http.post(this.baseUrl + 'update_mapped_stock', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  modifierInventoryMapping(product_id, inventory_id, mapping_weight) {
    const formdata = new FormData();
    formdata.append('modifier_id', product_id);
    formdata.append('inventory_id', inventory_id);
    formdata.append('mapping_weight', mapping_weight);
    return this.http.post(this.baseUrl + 'inventory_mod_mapping', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  modifierInventoryMappingEdit(mapping_id,product_id, inventory_id, mapping_weight) {
    const formdata = new FormData();
    formdata.append('id', mapping_id);
    formdata.append('modifier_id', product_id);
    formdata.append('inventory_id', inventory_id);
    formdata.append('mapping_weight', mapping_weight);
    return this.http.post(this.baseUrl + 'inventory_mod_mapping_edit', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  deleteProductInventoryMapping(product_id, inventory_id) {
    const formdata = new FormData();
    formdata.append('id', product_id);
    formdata.append('inventory_id', inventory_id);

    return this.http.post(this.baseUrl + 'remove_mapped_stock', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  deleteModifierInventoryMapping(id) {
    const formdata = new FormData();
    formdata.append('id', id);
    return this.http.post(this.baseUrl + 'remove_mapped_modifier_stock', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  updateStock(id, count) {
    const formdata = new FormData();
    formdata.append('id', id);
    formdata.append('count', count);
    return this.http.post(this.baseUrl + 'update_stock', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  wastage(data) {
    return this.http.post(this.baseUrl + 'wastage_add', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  inventoryWastageInventorySingle(data) {
    return this.http.post(this.baseUrl + 'wastage_add_single_inventory', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  wastageReport(from, to) {
    from = from.toISOString();
    to = to.toISOString();
    return this.http.get(this.baseUrl + 'wastage_report?from=' + from + '&to=' + to, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  wastageReportGroup(from, to) {
    from = from.toISOString();
    to = to.toISOString();
    return this.http.get(this.baseUrl + 'wastage_report_group?from=' + from + '&to=' + to, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  stockAlert() {
    return this.http.get(this.baseUrl + 'stock_alert', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  reflectSale() {
    return this.http.get(this.baseUrl + 'reflect_sale');
  }



  /////////add product orders

  addProductOrders(invoice_id, purchase_date, total_value, products, vendor_name,purchaseOrderId, vendor_id, staff_id, status, varience, notes, invoice_images_json, tax) {
    const pdate = moment(purchase_date).startOf('day').format('YYYY-MM-DD HH:mm:ss')
    const formdata = new FormData();
    formdata.append('invoice_id', invoice_id);
    formdata.append('purchase_date', pdate);
    formdata.append('total_value', total_value);
    formdata.append('cost_variance', varience);
    formdata.append('products', products);
    formdata.append('vendor_name', vendor_name);
    formdata.append('purchase_order_id', purchaseOrderId);
    formdata.append('vendor_id', vendor_id);
    formdata.append('staff_id', staff_id);
    formdata.append('status', status);
    formdata.append('notes', notes);
    formdata.append('invoice_images', invoice_images_json);
    formdata.append('tax', tax);
    return this.http.post(this.baseUrl + 'add_product_order', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  updateProductOrders(id, invoice_id, purchase_date, total_value, products, vendor_name, vendor_id, staff_id, status, varience, notes, invoice_images_json, tax) {
    const pdate = moment(purchase_date).startOf('day').format('YYYY-MM-DD HH:mm:ss')
    const formdata = new FormData();
    formdata.append('id', id);
    formdata.append('invoice_id', invoice_id);
    formdata.append('purchase_date', pdate);
    formdata.append('total_value', total_value);
    formdata.append('cost_variance', varience);
    formdata.append('products', products);
    formdata.append('vendor_name', vendor_name);
    formdata.append('vendor_id', vendor_id);
    formdata.append('staff_id', staff_id);
    formdata.append('status', status);
    formdata.append('notes', notes);
    formdata.append('tax', tax);
    formdata.append('invoice_images', invoice_images_json);
    return this.http.post(this.baseUrl + 'update_product_order', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  addProductOrdersPending(invoice_id, purchase_date, total_value, products, vendor_name, vendor_id, staff_id, status, poid, notes, is_scheduled, scheduled_date, tax,delivery_at=null) {
    const pdate = moment(purchase_date).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    const scheduled_date_formeted = scheduled_date?moment(scheduled_date).utc().format('YYYY-MM-DD HH:mm:ss'):null;
    let scheduled = is_scheduled ? '1' : '0';
    const formdata = new FormData();
    formdata.append('invoice_id', invoice_id);
    formdata.append('purchase_date', pdate);
    formdata.append('total_value', total_value);
    formdata.append('tax', tax);
    formdata.append('products', products);
    formdata.append('vendor_name', vendor_name);
    formdata.append('vendor_id', vendor_id);
    formdata.append('staff_id', staff_id);
    formdata.append('status', status);
    formdata.append('purchase_order_id', poid);
    if(notes){
      formdata.append('order_notes', notes);
    }
   
    formdata.append('is_mail_scheduled', scheduled);
    formdata.append('email_schedule', scheduled_date_formeted);
    formdata.append('delivery_at', delivery_at??null);
    return this.http.post(this.baseUrl + 'add_product_order_pending', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  deleteProductOrdersPending(data) {
    let payload={
      id:data
    };
    return this.http.post(this.baseUrl + 'remove_product_order_pending', payload, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  updateProductOrderMailSchedule(id, is_scheduled, scheduled_date) {
    const scheduled_date_formeted = moment(scheduled_date).utc().format('YYYY-MM-DD HH:mm:ss');
    let scheduled = is_scheduled ? '1' : '0';
    const formdata = new FormData();
    formdata.append('id', id);
    formdata.append('is_mail_scheduled', scheduled);
    formdata.append('email_schedule', scheduled_date_formeted);
    return this.http.post(this.baseUrl + 'product_order_mail_schedule', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  createOnlineOrderAutoDisable(start, end) {
    const from = moment(start).utc().format('YYYY-MM-DD HH:mm:ss');
    const to = moment(end).utc().format('YYYY-MM-DD HH:mm:ss');
    const formdata = new FormData();
    formdata.append('start', from);
    formdata.append('end', to);

    return this.http.post(this.baseUrl + 'online_order_auto_disable_add', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  updateOnlineOrderAutoDisable(id, start, end) {
    const from = moment(start).utc().format('YYYY-MM-DD HH:mm:ss');
    const to = moment(end).utc().format('YYYY-MM-DD HH:mm:ss');
    const formdata = new FormData();
    formdata.append('id', id);
    formdata.append('start', from);
    formdata.append('end', to);

    return this.http.post(this.baseUrl + 'online_order_auto_disable_update', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  getOnlineOrderAutoDisable() {

    return this.http.get(this.baseUrl + 'online_order_auto_disable', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  getOnlineOrderAutoDisableActive() {
    return this.http.get(this.baseUrl + 'online_order_auto_disable_active', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  removeOnlineOrderAutoDisable(id: any) {
    return this.http.get(this.baseUrl + 'online_order_auto_disable_remove?id=' + id, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }



  editProductOrdersPending(id, invoice_id, purchase_date, total_value, products, vendor_name, vendor_id, staff_id, status, poid, notes, is_scheduled) {
    // const pdate = moment(purchase_date).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    // const scheduled_date_formeted = moment(scheduled_date).utc().format('YYYY-MM-DD HH:mm:ss');
    let scheduled = is_scheduled ? '1' : '0';
    const formdata = new FormData();
    formdata.append('id', id);
    formdata.append('invoice_id', invoice_id);
    // formdata.append('purchase_date', pdate);
    formdata.append('total_value', total_value);
    formdata.append('products', products);
    formdata.append('vendor_name', vendor_name);
    formdata.append('vendor_id', vendor_id);
    formdata.append('staff_id', staff_id);
    formdata.append('status', status);
    formdata.append('purchase_order_id', poid);
    formdata.append('order_notes', notes);
    formdata.append('is_mail_scheduled', scheduled);
    // formdata.append('email_schedule', scheduled_date_formeted);
    return this.http.post(this.baseUrl + 'edit_product_order_pending', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }




  //////get all product orders

  getAllProductsOrders(from_date, to_date) {
    return this.http.get(this.baseUrl + 'all_product_orders?from_date=' + from_date + '&to_date=' + to_date, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      })
    })
  }

  getPendingProductsOrders() {
    return this.http.get(this.baseUrl + 'pending_product_orders', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      })
    })
  }

  getVendorProductsOrders(id) {
    return this.http.get(this.baseUrl + 'product_orders_by_vendor?id=' + id, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      })
    })
  }

  updateTableSideOrder(data) {
    return this.http.post(this.baseUrl + 'update_table_side_ordering_settings', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      })
    })
  }

  getTableSideOrder() {
    return this.http.get(this.baseUrl + 'get_table_side_ordering_settings', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      })
    })
  }

  getInventorySettings() {
    return this.http.get(this.baseUrl + 'inventory_settings_get', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      })
    })
  }

  updateInventorySettings(data) {
    return this.http.post(this.baseUrl + 'inventory_settings_update', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      })
    })
  }


  getVendorDeliveryDays(id) {
    return this.http.get(this.baseUrl + 'vendor_delivery_days_get?id=' + id, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      })
    })
  }

  updateVendorDeliveyDays(data) {
    return this.http.post(this.baseUrl + 'vendor_delivery_days_update', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      })
    })
  }


  getVendorProductsOrdersPending(id) {
    return this.http.get(this.baseUrl + 'product_orders_by_vendor_pending?id=' + id, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      })
    })
  }

  getMasterShopList() {
    return this.http.get(this.baseUrl + 'get_linked_shop_name_list', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      })
    })
  }




  ///////////////////xlsx product import

  importProductByXlsx(dat) {
    return this.http.post(this.baseUrl + 'product_import', dat, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  importModCatByXlsx(dat) {
    return this.http.post(this.baseUrl + 'modifierscategory_import', dat, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  importModfiersByXlsx(dat) {
    return this.http.post(this.baseUrl + 'modifiers_import', dat, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  ///////////////////xlsx Table import

  importTableByXlsx(dat) {
    return this.http.post(this.baseUrl + 'table_import', dat, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  importInventoriesByXlsx(dat) {
    return this.http.post(this.baseUrl + 'inventory_import', dat, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  /////////////////ticket details

  getTicketDetails(ticket_id) {
    const formdata = new FormData();
    formdata.append('ticket_id', ticket_id);
    return this.http.post(this.baseUrl + 'ticket/orders', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  /////////////////ticket details new
  
  getTicketTotalValue(ticket_id) {
    const formdata = new FormData();
    formdata.append('ticket_id', ticket_id);
    return this.http.post(this.baseUrl + 'get_ticket_total_value', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  /////////////email invoice

  emailInvoiceTicket(document, ticket_no, company_name, target_email, target_name) {
    const formdata = new FormData();
    formdata.append('document', document);
    formdata.append('ticket_no', ticket_no);
    formdata.append('company_name', company_name);
    formdata.append('target_email', target_email);
    formdata.append('target_name', target_name);
    return this.http.post(this.baseUrl + 'ticket/mail', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  ///////////get attendance report
  getAttendance(startDate, endDate, stime, etime) {
    console.log(startDate);
    console.log(endDate);
    const start_date = moment(startDate).set({ "hour": stime.split(':')[0], "minute": stime.split(':')[1] }).format('YYYY-MM-DD HH:mm:ss');
    const end_date = moment(endDate).set({ "hour": etime.split(':')[0], "minute": etime.split(':')[1] }).format('YYYY-MM-DD HH:mm:ss');
    return this.http.get(this.baseUrl + 'attendance_report?startDate=' + moment(start_date).utc().format() + '&endDate=' + moment(end_date).utc().format(), {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      })
    })
  }
  getAttendanceRecords(startDate, endDate, stime, etime) {
    console.log(startDate);
    console.log(endDate);
    const start_date = moment(startDate).set({ "hour": stime.split(':')[0], "minute": stime.split(':')[1] }).format('YYYY-MM-DD HH:mm:ss');
    const end_date = moment(endDate).set({ "hour": etime.split(':')[0], "minute": etime.split(':')[1] }).format('YYYY-MM-DD HH:mm:ss');
    return this.http.get(this.baseUrl + 'attendance_records?startDate=' + moment(start_date).utc().format() + '&endDate=' + moment(end_date).utc().format(), {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      })
    })
  }




  ///////get QR codes

  getTableQr() {
    return this.http.get(this.baseUrl + 'getTableQr', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  getUserStatusForPosStatus() {
    return this.http.get(this.baseUrl + 'get_user_status', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  getAllTerminals() {
    return this.http.post(this.baseUrl + 'getallterminal', '', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  setPosStatus(enable_pos) {
    const formdata = new FormData();
    formdata.append('enable_pos', enable_pos);
    return this.http.post(this.baseUrl + 'set_pos_status', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  setMainTerminal(terminal) {
    const formdata = new FormData();
    formdata.append('terminal', terminal);
    return this.http.post(this.baseUrl + 'set_main_terminal', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  uploadFile(file) {
    const formdata = new FormData();
    formdata.append('file', file);
    return this.http.post(this.baseUrl + 'fileUpload', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  imageUpload(file) {
    const formdata = new FormData();
    formdata.append('image', file);
    return this.http.post(this.baseUrl + 'image_upload', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  //////////////////////////////summary 


  // get hourly report


  // get cash drawe log

  getDrawerReport(from_date, to_date) {
    return this.http.get(this.baseUrl + 'drawerlog?from_date=' + from_date + '&to_date=' + to_date, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getCashinoutReport(from_date, to_date) {
    return this.http.get(this.baseUrl + 'cashinout_report?from_date=' + from_date + '&to_date=' + to_date, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  //Get void tickets
  getVoidTickets(from_date, to_date) {
    return this.http.get(this.baseUrl + 'void_report?from_date=' + from_date + '&to_date=' + to_date, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  //sync
  forcePush() {
    const formdata = new FormData();
    formdata.append('data', 'FORCEPUSH');
    return this.http.post(this.baseUrl + 'forcepush', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  getSyncStatus() {
    return this.http.get(this.baseUrl + 'get_sync_status', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }




  createProModcatMap(productCat, modifierCat) {

    const formdata = new FormData();
    formdata.append('product_id', productCat);
    formdata.append('modfiercat_id', modifierCat);

    return this.http.post(
      this.baseUrl + 'add_prod_modcat_mapping',
      formdata,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  editProModcatMap(id, productCat, modifierCat) {
    const formdata = new FormData();
    formdata.append('id', id);
    formdata.append('category_id', productCat);
    formdata.append('modifierscategory_id', modifierCat);
    formdata.append('forced_count', '1');
    return this.http.post(
      this.baseUrl + 'productmodifiersmapping_edit',
      formdata,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  getProModcatMap() {
    return this.http.get(this.baseUrl + 'get_prod_modcat_mapping', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  getProModcatMapAll() {
    return this.http.get(this.baseUrl + 'get_prod_modcat_mapping_all', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getProModifierMapAll() {
    return this.http.get(this.baseUrl + 'modifier_map_to_products_all', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getProbyModcatMap(id) {

    return this.http.get(this.baseUrl + 'get_prod_bymodcat?modfiercat_id=' + id, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  deleteProModcatMap(id) {

    const formdata = new FormData();
    formdata.append('id', id);
    return this.http.post(
      this.baseUrl + 'delete_prod_modcat_mapping',
      formdata,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }


  getCustomers() {
    return this.http.get(
      this.baseUrl + 'customersall',
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }
  setCustomerMembership(data) {
    return this.http.post(
      this.baseUrl + 'set_customer_membership',data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }
  addCustomerLoyaltyPoints(data) {
    return this.http.post(
      this.baseUrl + 'add_loyalty_points_to_customer',data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  
  getCustomerReports(id, from, to) {
    return this.http.get(
      this.baseUrl + 'customer_report?customer_id=' + id + '&from=' + from + '&to=' + to,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }



  createCustomer(name, email, tel, addressLine1, zipCode) {
    console.log(name);
    console.log(email);
    console.log(tel);
    console.log(addressLine1);

    const formdata = new FormData();
    formdata.append('name', name)
    formdata.append('tel', tel)

    formdata.append('addressLine1', addressLine1)
    formdata.append('email', email)
    formdata.append('zipCode', zipCode)

    return this.http.post(
      this.baseUrl + 'add_customer', formdata,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }


  editCustomer(id, name, email, tel, addressLine1, zipCode) {
    const formdata = new FormData();

    formdata.append('customer_id', id)
    formdata.append('tel', tel)
    formdata.append('name', name)
    formdata.append('addressLine1', addressLine1)
    formdata.append('email', email)
    formdata.append('zipCode', zipCode)

    return this.http.post(
      this.baseUrl + 'edit_customer', formdata,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  customerWalletTransactions(id) {
    return this.http.get(
      this.baseUrl + 'customer_wallet_transactions?customer_id=' + id,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  customerMembershipTypes() {
    return this.http.get(
      this.baseUrl + 'customer_membership_type_list',
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  customerMembershipTypeCreate(data) {
    return this.http.post(
      this.baseUrl + 'customer_membership_type_create',data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }
  customerMembershipTypeUpdate(id,data) {
    return this.http.post(
      this.baseUrl + 'customer_membership_type_update/'+id,data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }
  customerMembershipTypeDelete(id) {
    return this.http.post(
      this.baseUrl + 'customer_membership_type_delete/'+id,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  deleteCustomer(id) {
    const formdata = new FormData();
    formdata.append('id', id);
    return this.http.post(
      this.baseUrl + 'delete_customer', formdata,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }


  getCustomerTickets(id, start, end) {
    const start_date = moment(start).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    const end_date = moment(end).endOf('day').format('YYYY-MM-DD HH:mm:ss');




    return this.http.get(
      this.baseUrl +
      'get_all_customer_tickets?customer_id=' + id + '&from_date=' +
      moment(start_date).utc().format() +
      '&&to_date=' +
      moment(end_date).utc().format(),
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  getCustomerUnpaidTickets(id) {
    return this.http.get(
      this.baseUrl +
      'pay_later_tickets?customer_id=' + id,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }
  getCustomerTransaction(id, from, to) {
    return this.http.get(
      this.baseUrl +
      'get_payment_records?customer_id=' + id + '&from=' + moment(from).utc().format() + '&to=' + moment(to).utc().format(),
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }


  getCombo() {
    return this.http.get(this.baseUrl + 'get_combo',
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    )
  }
  getProductCombo() {
    return this.http.get(this.baseUrl + 'get_product_combo',
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    )
  }

  comboStatusUpdate(data) {
    return this.http.post(this.baseUrl + 'combo_active_update', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    )
  }

  getComboMappings(id) {
    return this.http.get(this.baseUrl + 'get_combo_mappings?combo_id=' + id,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    )
  }




  deleteCompoCat(id) {
    const formdata = new FormData();
    formdata.append("id", id);
    return this.http.post(this.baseUrl + 'delete_combo_cat_map', formdata,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    )

  }
  deleteCompoProd(id) {
    const formdata = new FormData();
    formdata.append("id", id);
    return this.http.post(this.baseUrl + 'delete_combo_prod_map', formdata,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    )

  }



  addCombo(val, img,availabilityData) {
    const start_date = moment(val.startdate).format('YYYY-MM-DD');
    const end_date = moment(val.enddate).format('YYYY-MM-DD');
    const formdata = new FormData();
    formdata.append("image", img);
    formdata.append("combo_name", val.comboname);
    formdata.append("description", val.description);
    formdata.append("startdate", start_date);
    formdata.append("enddate", end_date);
    formdata.append("combo_price", val.comboprice);
    formdata.append("combo_cat_id", val.combocat);
    formdata.append("is_product_combo", val.is_product_combo);
    formdata.append("table_price", val.table_price != null ? val.table_price : val.comboprice);
    formdata.append("availability", JSON.stringify(availabilityData));
    return this.http.post(this.baseUrl + 'add_combo', formdata,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    )
  }


  deleteCombo(id) {
    const formdata = new FormData();
    formdata.append("combo_id", id);

    return this.http.post(this.baseUrl + 'delete_combo', formdata,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    )
  }

  editCombo(id, val, img,availabilityData) {
    const start_date = moment(val.startdate).format('YYYY-MM-DD');
    const end_date = moment(val.enddate).format('YYYY-MM-DD');
    const formdata = new FormData();
    formdata.append("combo_id", id);
    formdata.append("image", img);
    formdata.append("combo_name", val.comboname);
    formdata.append("description", val.description);
    formdata.append("startdate", start_date);
    formdata.append("enddate", end_date);
    formdata.append("combo_price", val.comboprice);
    formdata.append("combo_cat_id", val.combocat);
    formdata.append("is_product_combo", val.is_product_combo);
    formdata.append("table_price", val.table_price != null ? val.table_price : val.comboprice);
    formdata.append("availability", JSON.stringify(availabilityData));


    return this.http.post(this.baseUrl + 'edit_combo', formdata,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    )
  }




  addComboCat(id, val) {
    const formdata = new FormData();
    formdata.append("combo_id", id);
    formdata.append("cat_id", val.cat_id);
    formdata.append("min", val.min);
    formdata.append("max", val.max);
    formdata.append("allow_same", val.allow_same ? '1' : '0');
    console.log(formdata);

    return this.http.post(this.baseUrl + 'add_combo_cat_map', formdata,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    )
  }

  addComboProd(id, val) {
    const formdata = new FormData();
    formdata.append("product_id", val.product_id);
    formdata.append("combo_id", id);
    formdata.append("min", val.min);
    formdata.append("max", val.max);
    formdata.append("allow_same", val.allow_same ? '1' : '0');
    return this.http.post(this.baseUrl + 'add_combo_prod_map', formdata,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    )
  }





  editCombocat(val) {
    const formdata = new FormData();
    formdata.append("id", val.id);
    formdata.append("max", val.max);
    formdata.append("min", val.min);
    formdata.append("allow_same", val.allow_same ? '1' : '0');
    return this.http.post(this.baseUrl + 'edit_combo_cat_map', formdata,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    )
  }

  editComboprod(val) {
    const formdata = new FormData();
    formdata.append("id", val.id);
    formdata.append("max", val.max);
    formdata.append("min", val.min);
    formdata.append("allow_same", val.allow_same ? '1' : '0');
    return this.http.post(this.baseUrl + 'edit_combo_prod_map', formdata,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    )
  }


  eatUberAuth() {

    const formdata = new FormData();
    formdata.append("client_secret", "qe7_AbIPFZXlCWYUNGSpURyzA5RuQKMZVLdF0gqo");
    formdata.append("client_id", "U9Fuyt-gOITHKZJK1gLvR3EEfwwcPYIg");
    formdata.append("grant_type", "authorization_code");
    formdata.append("scope", "pos_provisioning");


    return this.http.post('https://login.uber.com/oauth/v2/authorize', formdata)
  }

  getPosStatus() {
    let id = "065896b1-9b82-415c-a178-46273b642374";
    return this.http.get('https://api.uber.com/v1/eats/stores/' + id + '/pos_data')
  }




  getCashup(from, to) {
    //  from = moment(from).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    //  to = moment(to).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    console.log(" start :", from);
    console.log(" end :", to);
    return this.http.get(this.baseUrl + 'get_cash_up?from_date=' + from + '&to_date=' + to,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    )
  }

  getSurcharge() {
    return this.http.get(this.baseUrl + 'get_surcharge',
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    )
  }

  editSurcharge(data) {
    let dat = {
      label: data.label,
      value: data.value,
      id: data.id
    }
    console.log("id check", data.id);

    return this.http.post(this.baseUrl + 'edit_surcharge', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    )
  }

  deleteSurcharge(data) {
    let dat = {
      id: data.id
    }
    return this.http.post(this.baseUrl + 'delete_surcharge', dat,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    )
  }

  addSurcharge(data) {
    let dat = {
      label: data.label,
      value: data.value,

    }
    return this.http.post(this.baseUrl + 'add_surcharge', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    )
  }




  // promotions 

  getPromotions() {

    return this.http.get(this.baseUrl + 'get_promotions',
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      })
  }


  addPromotion(data) {
    return this.http.post(this.baseUrl + 'add_promotion', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      })
  }

  promoStatusUpdate(data) {
    return this.http.post(this.baseUrl + 'active_update', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      })
  }

  customerpromoStatusUpdate(data) {
    return this.http.post(this.baseUrl + 'customer-promotions-enable', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      })
  }
  customerpromoUpdate(data, id) {
    return this.http.post(this.baseUrl + 'customer-promotions-update/' + id, data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      })
  }
  customerpromoDelete(id) {
    return this.http.post(this.baseUrl + 'customer-promotions-delete/' + id, null,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      })
  }
  deletePromotion(data) {
    return this.http.post(this.baseUrl + 'delete_promotions', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      })

  }

  deletePromotionrule(data) {

    return this.http.post(this.baseUrl + 'delete_promotion_rule', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      })
  }


  updatePromotion(data) {
    return this.http.post(this.baseUrl + 'update_promotion', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      })
  }


  getModifiersPrefix() {
    return this.http.get(this.baseUrl + 'get_modifier_prefix',

      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      })
  }

  addModifiersPrefix(data) {
    return this.http.post(this.baseUrl + 'add_modifier_prefix', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      })
  }

  deleteModifiersPrefix(data) {
    return this.http.post(this.baseUrl + 'delete_modifier_prefix', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      })
  }

  updateModifiersPrefix(data) {
    return this.http.post(this.baseUrl + 'edit_modifier_prefix', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      })
  }

  getStripeAccId() {
    return this.http.get(this.baseUrl + 'getStripeAccId',
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      })
  }

  getGatewayConfig() {
    return this.http.get(this.baseUrl + 'get_gateway_config',
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      })
  }
  getPaymentGatewaystypes() {
    return this.http.get(this.baseUrl + 'get_gateways',
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      })
  }


  addStripeAccId(data) {
    return this.http.post(this.baseUrl + 'addStripeAccId', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      })
  }

  updateOnlineSettings(data) {
    return this.http.post(this.baseUrl + 'update_online_settings', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      })
  }


  confirmPassword(pass) {
    return this.http.post(this.baseUrl + 'confirmpass', pass,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      })
  }


  getOnlineMetadata() {
    return this.http.get(this.baseUrl + 'getonlineMetadataAdmin',
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      })

  }
  getInvoiceData() {
    return this.http.get(this.baseUrl + 'get_invoice_data',
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      })

  }
  updateInvoiceData(data) {
    return this.http.post(this.baseUrl + 'update_invoice_data', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      })

  }

  getUserOnlineMetaDataUpdate(add) {

    const formdata = new FormData();

    // formdata.append('is_closed', add.is_closed);
    formdata.append('delivery_buffer_time', add.delivery_buffer_time);
    formdata.append('pickup_buffer_time', add.pickup_buffer_time);
    formdata.append('pre_min_day', add.pre_min_day);
    formdata.append('online_inactive_reason', add.online_inactive_reason);
    formdata.append('website', add.website);
    formdata.append('dine_in', add.dine_in ? '1' : '0');
    formdata.append('pickup', add.pickup ? '1' : '0');
    formdata.append('is_preorder', add.is_preorder ? '1' : '0');
    formdata.append('online_order_active', add.online_order_active ? '1' : '0');
    formdata.append('delivery', add.delivery ? '1' : '0');
    formdata.append('table_reservation', add.tablereserve ? '1' : '0');
    formdata.append('order_ahead', add.orderahead ? '1' : '0');
    formdata.append('order_for_later', add.orderlater ? '1' : '0');
    formdata.append('tax', add.tax);
    formdata.append('online_payment_enable', add.online_payment_enable ? '1' : '0');
    formdata.append('surcharge_enable', add.surcharge_enable ? '1' : '0');
    formdata.append('surcharge_percent', add.surcharge_percent);
    formdata.append('surcharge_amount', add.surcharge_amount);


    return this.http.post(this.baseUrl + 'updateonlineMetadataAdmin', formdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }



  getNotificationEmails() {
    return this.http.get(this.baseUrl + 'get_notification_email',
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      });
  }

  addNotificationEmail(email, online, purchase_invoice, purchase_order_invoice_send,shift_end_report,table_reservation) {
    const formdata = new FormData();
    formdata.append('email', email);
    formdata.append('online_order_recieved', online == true || online == 1 ? '1' : '0');
    formdata.append('purchase_order_invoice', purchase_invoice == true || purchase_invoice == 1 ? '1' : '0');
    formdata.append('purchase_order_invoice_send', purchase_order_invoice_send == true || purchase_order_invoice_send == 1 ? '1' : '0');
    formdata.append('shift_end_report', shift_end_report == true || shift_end_report == 1 ? '1' : '0');
    formdata.append('table_reservation', table_reservation == true || table_reservation == 1 ? '1' : '0');


    return this.http.post(this.baseUrl + 'add_notification_email', formdata,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      });
  }

  deleteNotificationEmail(data) {
    return this.http.post(this.baseUrl + 'delete_notification_email', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      });
  }

  updateNotificationEmail(data) {
    return this.http.post(this.baseUrl + 'update_notification_email', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      });
  }



  getOnlinelist(from_date, to_date) {
    return this.http.get(this.baseUrl + 'get_online_order_list?from_date=' + from_date + '&to_date=' + to_date, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }



  updateShopImage(data) {
    return this.http.post(this.baseUrl + 'update_shop_image', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      });
  }


  getOnlineorderData(id) {
    return this.http.get(this.baseUrl + 'get_onlineOrder_data?order_id=' + id, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  getholidays() {
    return this.http.get(this.baseUrl + 'shop_holyday_get', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  addHoliday(data) {

    return this.http.post(this.baseUrl + 'shop_holyday_add', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      })
  }


  deleteHoliday(data) {

    return this.http.post(this.baseUrl + 'shop_holyday_delete', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      })
  }

  editHoliday(data) {

    return this.http.post(this.baseUrl + 'shop_holyday_edit', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      })
  }

  addCombogroup(data) {
    return this.http.post(this.baseUrl + 'combogroups_add', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      })
  }
  editCombogroup(data) {
    return this.http.post(this.baseUrl + 'combogroups_edit', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      })
  }

  getCombogroups(id) {
    return this.http.get(this.baseUrl + 'combogroups_get?id=' + id,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      });
  }
  deleteCombogroup(gid) {
    let data = {
      id: gid
    }
    return this.http.post(this.baseUrl + 'combogroups_delete', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      });
  }

  getCategoriesandProducts() {
    return this.http.get(this.baseUrl + 'category_list',
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      });
  }

  getCategoriesandProductsWithMaster() {
    return this.http.get(this.baseUrl + 'category_list_with_master',
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      });
  }

  mapCombogroupstoProducts(data) {
    return this.http.post(this.baseUrl + 'combogroup_product_mapping_add', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      });
  }

  editmapCombogroupstoProducts(data) {
    return this.http.post(this.baseUrl + 'combogroup_product_mapping_edit', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      });
  }


  getComboGroupdetails(id: any) {
    return this.http.get(this.baseUrl + 'combo_group_details?group_id=' + id,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      });
  }

  getCoupons() {
    return this.http.get(this.baseUrl + 'coupon_get_admin',
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      });
  }
  getCouponbyId(id) {
    return this.http.get(this.baseUrl + 'coupon_get_by_id?id=' + id,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      });
  }

  addCoupon(data: any) {
    return this.http.post(this.baseUrl + 'coupon_add', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      });

  }

  editCoupon(data: any) {
    return this.http.post(this.baseUrl + 'coupon_edit', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      });
  }

  deleteCoupon(data: any) {
    return this.http.post(this.baseUrl + 'coupon_delete', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      });
  }

  couponExposed(data: any) {
    return this.http.post(this.baseUrl + 'exposed_update', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      });
  }

  couponKiosk(data: any) {
    return this.http.post(this.baseUrl + 'kiosk_update', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      });
  }
  couponReport() {
    return this.http.get(this.baseUrl + 'coupon_report',
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      });
  }

  getcomboCategories() {
    return this.http.get(this.baseUrl + 'list_combo_cat_app',
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      });
  }

  createcomboCategory(data: any) {
    return this.http.post(this.baseUrl + 'add_combo_cat', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      });
  }

  editcomboCategory(data: any) {
    return this.http.post(this.baseUrl + 'edit_combo_cat', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      });
  }

  deletecomboCategory(data: any) {
    return this.http.post(this.baseUrl + 'delete_combo_cat', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      });
  }
  deletecomboCategoryMapped(data: any) {
    return this.http.post(this.baseUrl + 'delete_combo_cat_mapped_data', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      });
  }



  reportMail(start: Date, stime: any, end: Date, etime: any) {

    const start_date = moment(start).set({ "hour": stime.split(':')[0], "minute": stime.split(':')[1] }).format('YYYY-MM-DD HH:mm:ss');
    const end_date = moment(end).set({ "hour": etime.split(':')[0], "minute": etime.split(':')[1] }).format('YYYY-MM-DD HH:mm:ss');

    return this.http.get(this.baseUrl + 'testmailr?from_date=' + moment(start_date).utc().format() +
      '&&to_date=' +
      moment(end_date).utc().format(),
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );

  }



  reportmailConfigGet() {

    return this.http.get(this.baseUrl + 'report_mail_config_get',
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  reportmailConfigCreate(data: any) {

    return this.http.post(this.baseUrl + 'report_mail_config_create', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  reportmailConfigDelete(id: any) {
    let data = {
      id: id
    }
    return this.http.post(this.baseUrl + 'report_mail_config_delete', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  reportmailConfigUpdate(data: any) {

    return this.http.post(this.baseUrl + 'report_mail_config_update', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  getVendorList() {
    return this.http.get(this.baseUrl + 'vendor_get',
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  getVendorMetadata(id) {
    return this.http.get(this.baseUrl + 'vendor_metadata_get?vendor_id=' + id,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  updateVendorMetadata(data: any) {
    return this.http.post(this.baseUrl + 'vendor_metadata_update', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }
  externalDeliveryToggle(data: any) {
    return this.http.post(this.baseUrl + 'external_delivery_toggle', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }
  externalDeliveryStatus() {
    return this.http.get(this.baseUrl + 'external_delivery_status',
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  createVendor(data: any) {
    return this.http.post(this.baseUrl + 'vendor_create', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  updateVendor(data: any) {
    return this.http.post(this.baseUrl + 'vendor_update', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }



  deleteVendor(id: any) {
    let data = {
      id: id
    }
    return this.http.post(this.baseUrl + 'vendor_delete', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }



  discountReport(){

    let payload={
      "start_date": 1628291572328,
      "end_date": 1718348429019,
      "grouped": true
  }
    return this.http.post(this.baseUrl + 'discount_report_for_ticket_and_order',payload,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  couponSummeryReport(start,end){

    let payload={
      "start_date": start,
      "end_date": end,
      "return_response": "json"
  };
    return this.http.post(this.baseUrl + 'coupon_summary_detail_report',payload,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  couponReportwithId(){
    let payload={
      "start_date": "2022-06-13 06:40:21",
      "end_date": "2024-05-28 04:55:31",
      "coupon_id": 45,
      "return_response": "excel"
  }
    return this.http.post(this.baseUrl + 'coupon_wise_detail_report',payload,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }
  couponReportByCustomer(){
    let payload={
      "start_date": "2022-06-13 06:40:21",
      "end_date": "2024-05-28 04:55:31",
      "coupon_id": 45,
      "return_response": "excel"
  }
    return this.http.post(this.baseUrl + 'customer_wise_coupon_report',payload,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }



  getVendorMapList() {
    return this.http.get(this.baseUrl + 'vendor_map_get',
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  createVendorMap(data: any) {
    return this.http.post(this.baseUrl + 'vendor_map_create', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  updateVendorMap(data: any) {
    return this.http.post(this.baseUrl + 'vendor_map_update', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  deleteVendorMap(id: any) {
    let data = {
      id: id
    }
    return this.http.post(this.baseUrl + 'vendor_map_delete', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }






  getVendorbyInventory(id) {
    return this.http.get(this.baseUrl + 'vendor_map_by_inventory?id=' + id,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  getInventoryByVendor(id) {
    return this.http.get(this.baseUrl + 'inventory_by_vendor?id=' + id,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }


  getvendorPurchaseList(id: any) {
    return this.http.get(this.baseUrl + 'vendor_purchase_list?id=' + id,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  sendvendorPurchaseList(data: any) {
    let dat = {
      data: data
    };
    return this.http.post(this.baseUrl + 'vendor_purchase_mail', dat,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }



  getInventoryMappedProducts(id: any) {
    return this.http.get(this.baseUrl + 'inventory_mapped_products?id=' + id,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }
  getInventoryMappedModifiers(id: any) {
    return this.http.get(this.baseUrl + 'inventory_mapped_modifiers?id=' + id,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }


  inventoriesByStockTake(type) {
    let data = { 'type': type }
    return this.http.post(this.baseUrl + 'inventories_by_stock_take', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  create_stock_take(data) {

    return this.http.post(this.baseUrl + 'create_stock_take', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  get_stock_take_log() {

    return this.http.get(this.baseUrl + 'get_stock_take_log',
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  get_combo_report(start: Date, end: Date) {
    // const start_date = moment(start).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    // const end_date = moment(end).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    return this.http.get(this.baseUrl + 'comboreport?from_date=' +
      moment(start).utc().format() +
      '&&to_date=' +
      moment(end).utc().format(),
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  importSuppliersByXlsx(dat) {
    return this.http.post(this.baseUrl + 'vendor_import', dat, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }



  createProductWastage(data: any) {
    return this.http.post(this.baseUrl + 'product_wastage_create', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  getProductWastages(from, to) {

    // const start_date = moment(from).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    // const end_date = moment(to).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    from = from.toISOString();
    to = to.toISOString();
    return this.http.get(this.baseUrl + 'get_product_wastage?from=' + from + '&to=' + to,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  getProductWastagesGroup(from, to) {

    from = from.toISOString();
    to = to.toISOString();
    return this.http.get(this.baseUrl + 'get_product_wastage_group?from=' + from + '&to=' + to,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }


  importInventoriesByvendorXls(dat) {
    return this.http.post(this.baseUrl + 'inventorybyvendor_import', dat, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  onlineOrderStatusRetry(dat) {
    return this.http.get(this.baseUrl + 'retry_online_order_payment_status_check?order_id=' + dat, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  getShopShifts(from: any, to: any) {
    return this.http.get(this.baseUrl + 'get_shifts?from=' + from + '&to=' + to, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getShopShiftById(id: any) {
    return this.http.get(this.baseUrl + 'get_shiftbyId?id=' + id, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  getShopShiftByIdGroups(start: any, end: any) {
    return this.http.get(this.baseUrl + 'get_shiftbyId_groups?start=' + start + '&end=' + end, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getCashupsByShifts(id: any) {
    return this.http.get(this.baseUrl + 'cashup_by_shift?id=' + id, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  getCashupsByShiftsGroup(id1: any, id2: any, start, end) {
    return this.http.get(this.baseUrl + 'cashup_by_shift_group?id1=' + id1 + '&id2=' + id2 + '&start=' + start + '&end=' + end, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getQuickNotes() {
    return this.http.get(this.baseUrl + 'get_quick_note', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  createQuickNote(data: any) {
    return this.http.post(this.baseUrl + 'create_quick_note', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  deleteQuickNote(data: any) {
    return this.http.post(this.baseUrl + 'delete_quick_note', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  createUpdateWebBuilder(data: any) {
    return this.http.post(this.baseUrl + 'website_builder', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getWebBuilderData() {
    return this.http.get(this.baseUrl + 'get_web_data', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  deleteWebBuilderData(id: any) {
    return this.http.get(this.baseUrl + 'delete_web_data?id=' + id, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  getinventoryProductMappingList() {
    return this.http.get(this.baseUrl + 'inventoryProductMappingList', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  inventoryProductMappingImport(data) {
    return this.http.post(this.baseUrl + 'inventoryProductMappingImport', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  combosImport(data) {
    return this.http.post(this.baseUrl + 'combos_import', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  StaffLoginUpdate(data) {
    return this.http.post(this.baseUrl + 'staff_login_update', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getProductsComboMapping(id: any) {
    return this.http.get(this.baseUrl + 'product_combo_mappingby_id?id=' + id, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  createProductsComboMapping(data: any) {
    return this.http.post(this.baseUrl + 'product_combo_mapping_create', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  deleteProductComboMapping(id) {
    return this.http.get(this.baseUrl + 'product_combo_mapping_delete?id=' + id, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  getKioskImages() {
    return this.http.get(this.baseUrl + 'kiosk_images', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  deleteKioskImage(id) {
    return this.http.get(this.baseUrl + 'delete_kiosk_images?id=' + id, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  addKioskImage(data) {
    return this.http.post(this.baseUrl + 'image_store', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  updateKioskImage(data) {
    return this.http.post(this.baseUrl + 'image_store_update', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  getPricetagsall() {
    return this.http.get(this.baseUrl + 'pricetag_all', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  ValidateStaff(data: any) {
    return this.http.post(this.baseUrl + 'validateStaffInAdmin', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getStaffNames() {
    return this.http.get(this.baseUrl + 'getStaffNames', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  gettableBookings(from_date, to_date) {
    return this.http.get(this.baseUrl + 'get_table_booking?from_date=' + from_date + '&to_date=' + to_date, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  gettableBookingsToday() {
    return this.http.get(this.baseUrl + 'get_bookings_today', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  exportPdftableBookingsByDate(data) {
    return this.http.post(this.baseUrl + 'table_booking_pdf_export_by_date', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
      responseType: 'blob',
      observe: 'response',

    });
  }
  gettableBookingsWithStatus(status) {
    return this.http.get(this.baseUrl + 'get_bookings_with_status?reservation_status=' + status, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  updatetableBookingsWithStatus(id, status) {
    let payload = {
      reservation_id: id,
      status: status
    };
    return this.http.post(this.baseUrl + 'update_table_booking_status', payload, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  assigntableBookingTable(id, table_id) {
    let payload = {
      reservation_id: id,
      table_id: table_id
    };
    return this.http.post(this.baseUrl + 'assign_table_booking_table', payload, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  getTablesList() {
    return this.http.get(this.baseUrl + 'table_list_to_admin', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getStaffList() {
    return this.http.get(this.baseUrl + 'staff_list', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getQuickSettings() {
    return this.http.get(this.baseUrl + 'getQuickSettings', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  updateQuickSettings(data: any) {
    return this.http.post(this.baseUrl + 'updateQuickSettings', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  updateQuickSettingsSelections(data: any) {
    return this.http.post(this.baseUrl + 'updateQuickSettingsSelections', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  updateStaffClockTime(data: any) {
    return this.http.post(this.baseUrl + 'update_staff_clock_time', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  updateStaffBreakTime(data: any) {
    return this.http.post(this.baseUrl + 'update_staff_break_time', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }



  updateGateWayToStripe(data: any) {
    return this.http.post(this.baseUrl + 'update_gateway_stripe', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  updateGateWayToWindcave(data: any) {
    return this.http.post(this.baseUrl + 'update_gateway_windcave', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  updateGateWayToPayrix(data: any) {
    return this.http.post(this.baseUrl + 'update_gateway_payrix', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  getUberTocken(data: any) {
    return this.http.post(this.baseUrl + 'get_uber_tocken', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getUberStores(data: any) {
    return this.http.post(this.baseUrl + 'get_uber_store', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getUberStoresToProvision(data: any) {
    return this.http.post(this.baseUrl + 'get_uber_store_to_provision', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  deleteUberStoresProvision(data: any) {
    return this.http.post(this.baseUrl + 'delete_uber_store_provision', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  activateUberStore(data: any) {
    return this.http.post(this.baseUrl + 'provision_uber_store', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  updateUberStoreProvisioning(data: any) {
    return this.http.post(this.baseUrl + 'update_uber_store_provision', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getUserId() {
    return this.http.get(this.baseUrl + 'get_uid', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  addUberStoreProvisioned(data: any) {
    return this.http.post(this.baseUrl + 'add_uber_store_provisioned', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getUberConnectedStores() {
    return this.http.get(this.baseUrl + 'get_uber_stores_provisioned', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getUberStoreId() {
    return this.http.get(this.baseUrl + 'get_uber_store_id', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  getUberStore(id) {
    return this.http.get(this.baseUrl + 'get_uber_store?id=' + id, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  uberMenuSyncNode(storeId: any) {
    let data = null;
    return this.http.put(this.baseUrlNode + 'uber/uber_menu_sync?storeId=' + storeId, data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  uberMenuSync(storeId:any){
      let data={
        "storeId": storeId
      };
    return this.http.post(this.baseUrl + 'update_uber_menu', data,{
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  createCashInOutReason(data: any) {
    return this.http.post(this.baseUrl + 'cash_in_out_reason_create', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getCashInOutReason() {
    return this.http.get(this.baseUrl + 'cash_in_out_reason_admin', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  deteleCashInOutReason(data: any) {
    return this.http.post(this.baseUrl + 'cash_in_out_reason_delete', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  updateCashInOutReason(data: any) {
    return this.http.post(this.baseUrl + 'cash_in_out_reason_update', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  fileUploadS3(data: any) {
    return this.http.post(this.baseUrl + 'fileUpload_s3', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getUserFiles() {
    return this.http.get(this.baseUrl + 'get_user_files', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  getOnlineSettings() {
    return this.http.get(this.baseUrl + 'online_order_settings_get', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  updateOnlineOrderSettings(data: any) {
    return this.http.post(this.baseUrl + 'online_order_settings', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  updateloyaltySettings(data: any) {
    return this.http.post(this.baseUrl + 'update_loyality_enable_prod_level', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  getloyaltySettings() {
    return this.http.get(this.baseUrl + 'get_loyality_enable_prod_level', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  updateShopLogo(data: any) {
    return this.http.post(this.baseUrl + 'updateShopLogo', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  getShopLogo() {
    return this.http.get(this.baseUrl + 'getShopLogo', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  // customer display



  addCustomerDisplayimage(data: any) {
    return this.http.post(this.baseUrlNode + 'customer/customerdisplayim', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  deleteCustomerDisplayimage(id: any) {
    return this.http.delete(this.baseUrlNode + 'customer/deletecustomerdisplayim/' + id, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getCustomeDisplayImages() {
    return this.http.get(this.baseUrlNode + 'customer/getcustomerdisplayimages', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  getIssues() {
    return this.http.get(this.baseUrl + 'get_issues', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  getIssueById(id) {
    return this.http.get(this.baseUrl + 'get_issue_by_id?id=' + id, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  createIssue(data: any) {
    return this.http.post(this.baseUrl + 'add_issue', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  updateIssue(data: any) {
    return this.http.post(this.baseUrl + 'update_issue', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  updateIssueThread(data) {

    return this.http.post(this.baseUrl + 'update_issue_thread', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  checkBarcode(code: any, id: any) {
    const fromdata = new FormData();
    if (id) {
      fromdata.append('id', id);
    }
    fromdata.append('code', code);
    return this.http.post(this.baseUrl + 'check_barcode', fromdata, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  createUpdateReportSettings(data: any) {

    return this.http.post(this.baseUrl + 'create_report_settings', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getReportSettings() {

    return this.http.get(this.baseUrl + 'get_report_settings', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getProductSizes(id: any) {
    return this.http.get(this.baseUrl + 'productsize_product?id=' + id, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getAllProductSizes() {
    return this.http.get(this.baseUrl + 'productsize_all', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getMenuTypes() {
    return this.http.get(this.baseUrl + 'get_menu_types', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  getWhatsappAlerts() {
    return this.http.get(this.baseUrl + 'list_whatsapp_alert', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  createWhatsappAlerts(data: any) {
    return this.http.post(this.baseUrl + 'create_whatsapp_alert', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  editWhatsappAlerts(data: any) {
    return this.http.post(this.baseUrl + 'edit_whatsapp_alert', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  deleteWhatsappAlert(data: any) {
    return this.http.post(this.baseUrl + 'delete_whatsapp_alert', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  excludeSizeModifier(data: any) {
    return this.http.post(this.baseUrl + 'exclude_modifier', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  undoexcludeSizeModifier(data: any) {
    return this.http.post(this.baseUrl + 'undo_exclude_modifier', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  getLinkedShops() {
    return this.http.get(this.baseUrl + 'get_linked_shop_admin', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  getLinkedWithMsaterShops() {
    return this.http.get(this.baseUrl + 'get_linked_shop_with_master_admin', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  getTokenWithMaster(id) {
    return this.http.get(this.baseUrl + 'store_token_master?user_id=' + id, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  
  ticketInvoiceToMail(data) {
    return this.http.post(this.baseUrl + 'ticket_invoice_mail', data,{
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  createDataExclusion(data: any) {
    return this.http.post(this.baseUrl + 'dataexclusion_create', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  getDataExclusion(data: any) {
    return this.http.post(this.baseUrl + 'dataexclusion_get_by_item', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  getPaymentGateways() {
    return this.http.get(this.baseUrl + 'get_payment_gateways', {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }

  configPaymentGateway(data: any) {
    return this.http.post(this.baseUrl + 'payment_gateway_config_create', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  configPaymentGatewayEdit(data: any) {
    return this.http.post(this.baseUrl + 'payment_gateway_config_edit', data, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }
  configPaymentGatewayDelete(data: any) {
    let payload = {
      id: data
    };
    return this.http.post(this.baseUrl + 'payment_gateway_config_delete', payload, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.authService.get().getValue(),
      }),
    });
  }


  getCustomerPromotions() {
    return this.http.get(
      this.baseUrl +
      'customer-promotions-list',
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }


  createCustomerPromotion(data) {
    return this.http.post(
      this.baseUrl +
      'customer-promotions-create', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }



  // product suggestions

  productSuggestionAdd(data) {
    return this.http.post(
      this.baseUrl +
      'product_suggestion_add_edit', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }
  cartSuggestionAdd(data) {
    return this.http.post(
      this.baseUrl +
      'cart_suggestion_create', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  productSuggestionByProduct(id) {
    return this.http.get(
      this.baseUrl +
      'product_suggestion_by_product?id='+id,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }
  cartSuggestionByDomain(domain) {
    return this.http.get(
      this.baseUrl +
      'cart_suggestion_list?domain='+domain,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  productSuggestionListAll(id) {
    return this.http.get(
      this.baseUrl +
      'product_suggestion_list?id='+id,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  productSuggestionDelete(id) {
    return this.http.get(
      this.baseUrl +
      'product_suggestion_delete?id='+id,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }


  //customer journel

  

  getCustomerJournelByCusID() {
    return this.http.get(
      this.baseUrl +
      'get_customer_journals',
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }
  getCustomerJournelTicketsToday() {
    return this.http.get(
      this.baseUrl +
      'todays_customer_journal_tickets',
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  getCustomerJournelTicketsByDate(from_date,to_date) {
    const start=moment(from_date).utc().format();
    const end=moment(to_date).utc().format();
    return this.http.get(
      this.baseUrl +
      'customer_journal_tickets_by_date?from_date=' + start + '&to_date=' + end,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }
  getCustomerJournelsToday() {
    return this.http.get(
      this.baseUrl +
      'todays_customer_journal',
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }
  getCustomerJournelsByDate(from_date,to_date) {
    const start=moment(from_date).utc().format();
    const end=moment(to_date).utc().format();
    return this.http.get(
      this.baseUrl +
      'customer_journal_by_date?from_date=' +start+ '&to_date=' + end,
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  getCustomerBalances() {
    return this.http.get(
      this.baseUrl +
      'customer_journal_customer_balance',
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }
  getCustomerJournelTicketsByCusID(id,start,end) {
    return this.http.get(
      this.baseUrl +
      'customer_journal_tickets_by_cust_id?customer_id=' + id+'&from_date='+moment(start).utc().format()+'&to_date='+moment(end).utc().format(),
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }
  getCustomerJournels(id,start,end) {
   
    return this.http.get(
      this.baseUrl +'get_customer_journals?customer_id=' + id+'&from_date='+moment(start).utc().format()+'&to_date='+moment(end).utc().format(),
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.authService.get().getValue(),
        }),
      },
    );
  }

  // end ########################################################################################3

}
